// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import _filter from 'lodash/filter';
import { listBrands } from './model/Brand.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import BrandsListPageView from './views/BrandsListPageView';
import type { BrandsListPageViewProps } from './views/BrandsListPageView';
import type { Brand } from './model/Brand.model';
import { apolloMutate } from '../core/data/apolloClient';

type BrandsListState = {
  brands: null | Array<Brand> | ErrorCapsule,
}

const brandShape = `{
  id
  name
  logo {
    id
    fit(width: 100, height: 100) { width height url }
  }
}`;

const initialState: BrandsListState = {
  brands: null,
};

const ACTION_SET_BRANDS = 'library/brandsList/ACTION_SET_BRANDS';
const ACTION_DELETE_BRAND = 'library/brandsList/ACTION_DELETE_BRAND';

function setBrands(brands: null | Array<Brand> | ErrorCapsule) {
  return {
    type: ACTION_SET_BRANDS,
    brands,
  };
}

async function loadBrands(filter?: ?{ [string]: string }) {
  try {
    const brands = await listBrands({ ...filter, _sort: 'name' }, brandShape);

    return setBrands(brands);
  } catch (err) {
    return setBrands(new ErrorCapsule(err, () => [
      setBrands(initialState.brands),
      loadBrands({ ...filter, _sort: 'name' }),
    ]));
  }
}

async function deleteBrand(id) {
  await apolloMutate(
    gql`
      mutation ($id: String!) {
        deleteBrand(id: $id)
      }
    `,
    { id }
  );

  return {
    type: ACTION_DELETE_BRAND,
    id,
  };
}

export function brandsListReducer(
  state: BrandsListState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_BRANDS:
      return {
        ...state,
        brands: action.brands,
      };

    case ACTION_DELETE_BRAND:
      return {
        ...state,
        brands: _filter(state.brands, ({ id }) => id !== action.id),
      };

    default:
      return state;
  }
}

const BrandsListPage = connect(
  ({ library }): BrandsListPageViewProps => ({
    brands: library.brandsList.brands,
  }),
)(class extends Component<BrandsListPageViewProps> {
  constructor(props) {
    const { dispatch } = props;
    super(props);

    dispatch([
      setBrands(null),
      loadBrands(/* { field: mode } */),
    ]);
  }

  render() {
    const { dispatch } = this.props;
    return (
      <BrandsListPageView
        {...this.props}
        onDeleteBrand={(id) => dispatch(deleteBrand(id))}
      />
    );
  }
});

export function brandsListPageRoutes(): Routes {
  return [
    {
      title: 'Бренды',
      path: '/brands/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <BrandsListPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
