// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

export type DialogWidgetViewProps = {
  renderBody: () => React$Node,
  renderTitle?: () => React$Node,
  renderFooter?: () => React$Node,
  showClose?: boolean,
  onClose: () => void,
}

type CallbackProps = {
  onClose: () => void,
};

type ConfirmFooterProps = CallbackProps & {
  handler: () => void,
};

export function ConfirmFooter({ onClose, handler }: ConfirmFooterProps) {
  const { t } = useTranslation();

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        onClick={e => {
          onClose();
          handler();
        }}
      >
        {t('OK')}
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={e => onClose()}
      >
        {t('Cancel')}
      </button>
    </>
  );
}

export default function DialogWidgetView({
  renderBody,
  renderTitle,
  renderFooter,
  showClose,
  onClose,
}: DialogWidgetViewProps) {
  const { t } = useTranslation();

  const callbackProps = { onClose };

  return (
    <>
      <div className="modal fade show d-block" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {renderTitle && (
              <div className="modal-header">
                <h5 className="modal-title">{renderTitle(callbackProps)}</h5>
                {showClose && (
                  <button
                    type="button"
                    className="close"
                    aria-label={t('Close')}
                    onClick={e => onClose()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                )}
              </div>
            )}
            <div className="modal-body">
              {renderBody(callbackProps)}
            </div>
            {renderFooter && (
              <div className="modal-footer">
                {renderFooter(callbackProps)}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
}
