// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { RouteDef } from 'metaup/routing/routingUtils';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import Error404PageView from './views/Error404PageView';
import type { Error404PageViewProps } from './views/Error404PageView';

type Error404State = {
  error404: null | {} | ErrorCapsule,
}

const initialState: Error404State = {
  error404: null,
};

const ACTION_SET_ERROR_404 = 'statics/error404/ACTION_SET_ERROR_404';

function setError404(error404: null | {} | ErrorCapsule) {
  return {
    type: ACTION_SET_ERROR_404,
    error404,
  };
}

async function loadError404() {
  try {
    const error404 = {};
    return setError404(error404);
  } catch (err) {
    return setError404(new ErrorCapsule(err, () => [
      setError404(initialState.error404),
      loadError404(),
    ]));
  }
}

export function error404Reducer(
  state: Error404State = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_ERROR_404:
      return {
        ...state,
        error404: action.error404,
      };

    default:
      return state;
  }
}

const Error404Page = connect(
  ({ statics }): Error404PageViewProps => ({
    error404: statics.error404.error404,
  }),
)(class extends Component<Error404PageViewProps> {
  constructor(props) {
    const { dispatch } = props;
    super(props);

    dispatch([
      setError404(null),
      loadError404(),
    ]);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setError404(null));
  }

  render() {
    // const { field } = this.props;
    return (
      <Error404PageView
        {...this.props}
      />
    );
  }
});

export default Error404Page;

export function error404PageCatchingRoute(): RouteDef {
  return {
    title: t => t('Error 404'),
    path: '/',
    isPrefix: true,
    render: params => (
      <Error404Page
        {...sanitizeRouteParams(params, {
          id: 'id',
        })}
      />
    ),
    design: require('./views/Error404PageView.design@mobile.png'), // eslint-disable-line global-require
  };
}
