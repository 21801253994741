// @flow
import { connect } from 'react-redux';
import { ApolloError } from 'apollo-client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import LoadFailedView from './LoadFailedView';
import errorMessages from '../core/exceptions/errorMessages';

type Props = {
  error: string | ErrorCapsule | Error,
}

export function decodeException(e: Error) {
  if (e instanceof ApolloError) {
    if (e.networkError) {
      return errorMessages.connectionProblems;
    }
    // Do not show everything to user at once. Focus on the first.
    // If we'll face some cases where we need different approach, we'll update this logic.
    // Don't change it until then.
    if (e.graphQLErrors && e.graphQLErrors[0]) {
      const gqlErr = e.graphQLErrors[0];
      if (gqlErr.extensions && gqlErr.extensions.code === 'NOT_FOUND') {
        return errorMessages.pageNotFound;
      }
      return gqlErr.message;
    }
  }
  return errorMessages.internalProblems;
}

export default connect(
  (_, { error }: Props) => {
    if (error instanceof ErrorCapsule) {
      return { message: decodeException(error.error) };
    }
    if (error instanceof Error) {
      return { message: decodeException(error) };
    }
    return { message: error };
  },
  (dispatch, { error }: Props) => {
    if (error instanceof ErrorCapsule && error.retry) {
      return {
        onRetry: () => {
          const action = error.retry();
          if (action) {
            dispatch(action);
          }
        },
      };
    }
    return {};
  }
)(LoadFailedView);

export const testOnly = {
  decodeException,
};
