// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { authAuditMetaModel } from './AuthAudit.model';

export function filterAuthAuditInput(values) {
  return _pickBy(values, (_, key) => key in authAuditMetaModel.rules || key === 'id');
}

export function listAuthAudits(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: AuthAuditsFilter) {
        listAuthAudits(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getAuthAudit(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getAuthAudit(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createAuthAudit(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: AuthAuditCreate!) {
        createAuthAudit(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updateAuthAudit(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: AuthAuditUpdate!) {
        updateAuthAudit(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
