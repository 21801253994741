// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import {
  getVariant,
  createVariant,
  updateVariant,
  filterVariantInput,
} from './model/Variant.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import VariantEditPageView from './views/VariantEditPageView';
import type { VariantEditPageViewProps } from './views/VariantEditPageView';
import type { Variant } from './model/Variant.model';
import { getDevice } from './model/Device.client';

type VariantEditState = {
  variant: null | Variant | ErrorCapsule,
}

const deviceShape = `{
  id
  name
  brand { id name }
}`;

const variantShape = `{
  id
  deviceId
  device ${deviceShape}
  name
  preview {
    id
    fit(width: 100, height: 100) { width height url }
  }
  cut { id }
  source { id }
  guide
  notes
  isEnabled
}`;

const initialState: VariantEditState = {
  variant: null,
};

const ACTION_SET_VARIANT = 'library/variantEdit/ACTION_SET_VARIANT';

function setVariant(variant: null | Variant | ErrorCapsule) {
  return {
    type: ACTION_SET_VARIANT,
    variant,
  };
}

async function loadVariant(id: string) {
  try {
    const variant = await getVariant(id, variantShape);

    return setVariant(variant);
  } catch (err) {
    return setVariant(new ErrorCapsule(err, () => [
      setVariant(initialState.variant),
      loadVariant(id),
    ]));
  }
}

async function loadEmptyVariant(deviceId: string) {
  try {
    const device = await getDevice(deviceId, deviceShape);

    return setVariant({ deviceId, device, isEnabled: true });
  } catch (err) {
    return setVariant(new ErrorCapsule(err, () => [
      setVariant(initialState.variant),
      loadEmptyVariant(deviceId),
    ]));
  }
}

export async function saveVariant(values) {
  const variant = !values.id
    ? await createVariant(filterVariantInput(values), variantShape)
    : await updateVariant(filterVariantInput(values), variantShape);

  return [
    setVariant(variant),
    redirect(
      values.id
        ? `/library/variant/${variant.id}/`
        : `/library/variants/${variant.deviceId}/`
    ),
  ];
}

export function variantEditReducer(
  state: VariantEditState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_VARIANT:
      return {
        ...state,
        variant: action.variant,
      };

    default:
      return state;
  }
}

const VariantEditPage = connect(
  ({ library }): VariantEditPageViewProps => ({
    variant: library.variantEdit.variant,
  }),
)(class extends Component<VariantEditPageViewProps> {
  constructor(props) {
    const { id, deviceId, dispatch } = props;
    super(props);

    dispatch(id ? [
      setVariant(null),
      loadVariant(id),
    ] : [
      loadEmptyVariant(deviceId),
    ]);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setVariant(null));
  }

  render() {
    const { dispatch } = this.props;
    return (
      <VariantEditPageView
        {...this.props}
        onSubmit={(values: Variant) => dispatch(saveVariant(values))}
      />
    );
  }
});

export function variantEditPageRoutes(): Routes {
  return [
    {
      title: 'Добавление варианта',
      path: '/variant-add/:deviceId/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <VariantEditPage
          {...sanitizeRouteParams(params, {
            deviceId: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
    {
      title: 'Редактирование варианта',
      path: '/variant-edit/:id/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <VariantEditPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
