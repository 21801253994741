// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';

export type Error404PageViewProps = {
  error404: null | {} | ErrorCapsule,
}

export default function Error404PageView({
  error404,
}: Error404PageViewProps) {
  const { t } = useTranslation();

  if (!error404) return <Loader />;
  if (error404 instanceof ErrorCapsule) return <LoadFailed error={error404} />;

  return (
    <div className="error-404">
      <h1>{t('Error 404')}</h1>
    </div>
  );
}
