// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import _filter from 'lodash/filter';
import { listFranchisees } from './model/Franchisee.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import FranchiseesListPageView from './views/FranchiseesListPageView';
import type { FranchiseesListPageViewProps } from './views/FranchiseesListPageView';
import type { Franchisee } from './model/Franchisee.model';
import { apolloMutate } from '../core/data/apolloClient';

type FranchiseesListState = {
  franchisees: null | Array<Franchisee> | ErrorCapsule,
}

const franchiseeShape = `{
  id
  title
  notes
}`;

const initialState: FranchiseesListState = {
  franchisees: null,
};

const ACTION_SET_FRANCHISEES = 'org/franchiseesList/ACTION_SET_FRANCHISEES';
const ACTION_DELETE_FRANCHISEE = 'org/franchiseesList/ACTION_DELETE_FRANCHISEE';

function setFranchisees(franchisees: null | Array<Franchisee> | ErrorCapsule) {
  return {
    type: ACTION_SET_FRANCHISEES,
    franchisees,
  };
}

async function loadFranchisees(filter?: ?{ [string]: string }) {
  try {
    const franchisees = await listFranchisees({
      ...filter,
      isDeleted: false,
      _sort: 'title',
    }, franchiseeShape);

    return setFranchisees(franchisees);
  } catch (err) {
    return setFranchisees(new ErrorCapsule(err, () => [
      setFranchisees(initialState.franchisees),
      loadFranchisees(filter),
    ]));
  }
}

async function deleteFranchisee(id) {
  await apolloMutate(
    gql`
      mutation ($id: String!) {
        deleteFranchisee(id: $id)
      }
    `,
    { id }
  );

  return {
    type: ACTION_DELETE_FRANCHISEE,
    id,
  };
}

export function franchiseesListReducer(
  state: FranchiseesListState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_FRANCHISEES:
      return {
        ...state,
        franchisees: action.franchisees,
      };

    case ACTION_DELETE_FRANCHISEE:
      return {
        ...state,
        franchisees: _filter(state.franchisees, ({ id }) => id !== action.id),
      };

    default:
      return state;
  }
}

const FranchiseesListPage = connect(
  ({ org }): FranchiseesListPageViewProps => ({
    franchisees: org.franchiseesList.franchisees,
  }),
)(class extends Component<FranchiseesListPageViewProps> {
  constructor(props) {
    const { dispatch } = props;
    super(props);

    dispatch([
      setFranchisees(null),
      loadFranchisees(/* { field: mode } */),
    ]);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setFranchisees(null));
  }

  render() {
    const { dispatch } = this.props;
    return (
      <FranchiseesListPageView
        {...this.props}
        onDeleteFranchisee={(id) => dispatch(deleteFranchisee(id))}
      />
    );
  }
});

export function franchiseesListPageRoutes(): Routes {
  return [
    {
      title: 'Франчайзи',
      path: '/franchisees/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <FranchiseesListPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
