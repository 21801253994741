// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required } from 'redux-form-validators';
import { string } from '../../core/data/rules';
import type { MetaModel } from '../../core/data/rules';

export type PrintClientSession = {|
  id: string,
  startedAt: string,
  lostAt: ?string,
  heartBeat: string,
  ip: string,
  logExtra: ?string,
  clientId: ?string,
  clientSessionId: ?string,
  availablePrinters: any,
|}


export const printClientSessionMetaModel: MetaModel = {
  name: 'PrintClientSession',
  rules: {
    startedAt: [string(), required()],
    lostAt: [string()],
    heartBeat: [string(), required()],
    ip: [string(), required()],
    logExtra: [string()],
    clientId: [string()],
    clientSessionId: [string()],
    availablePrinters: [],
  },
};
