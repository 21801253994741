// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { StatsOnFranchisee } from './model/StatsOnFranchisee.model';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import FranchiseeSalesPageView from './views/FranchiseeSalesPageView';
import { apolloQuery } from '../core/data/apolloClient';

type FranchiseeSalesState = {
  statsOnFranchisees: null | Array<StatsOnFranchisee> | ErrorCapsule,
}

const statsOnFranchiseeShape = `{
  franchiseeId
  franchisee {
    id
    title
    notes
    isDeleted
  }
  totalCuts
}`;

const initialState: FranchiseeSalesState = {
  statsOnFranchisees: null,
};

const ACTION_SET_STATS_ON_FRANCHISEES = 'stats/franchiseeSales/ACTION_SET_STATS_ON_FRANCHISEES';

function setStatsOnFranchisees(statsOnFranchisees: null | Array<StatsOnFranchisee> | ErrorCapsule) {
  return {
    type: ACTION_SET_STATS_ON_FRANCHISEES,
    statsOnFranchisees,
  };
}

async function loadStatsOnFranchisees() {
  try {
    const statsOnFranchisees = await apolloQuery(
      gql`
        query {
          getStatsOnFranchisees ${statsOnFranchiseeShape}
        }
      `
    );

    return setStatsOnFranchisees(statsOnFranchisees);
  } catch (err) {
    return setStatsOnFranchisees(new ErrorCapsule(err, () => [
      setStatsOnFranchisees(initialState.statsOnFranchisees),
      loadStatsOnFranchisees(),
    ]));
  }
}

export function franchiseeSalesReducer(
  state: FranchiseeSalesState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_STATS_ON_FRANCHISEES:
      return {
        ...state,
        statsOnFranchisees: action.statsOnFranchisees,
      };

    default:
      return state;
  }
}

type Props = {}

function FranchiseeSalesPage({}: Props) {
  const dispatch = useDispatch();
  const {
    statsOnFranchisees,
  }: FranchiseeSalesState = useSelector(({ stats }) => stats.franchiseeSales);

  // Load StatsOnFranchisees
  useEffect(() => {
    dispatch([
      setStatsOnFranchisees(null),
      loadStatsOnFranchisees(),
    ]);
    return () => dispatch(setStatsOnFranchisees(null));
  }, []);

  // Render
  return (
    <FranchiseeSalesPageView
      statsOnFranchisees={statsOnFranchisees}
    />
  );
}

export function franchiseeSalesPageRoutes(): Routes {
  return [
    {
      title: 'Статистика по франчам',
      path: '/franchisee-sales/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <FranchiseeSalesPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
