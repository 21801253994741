// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import _filter from 'lodash/filter';
import { listUsers } from './model/User.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import UsersListPageView from './views/UsersListPageView';
import type { User } from './model/User.model';
import { apolloMutate } from '../core/data/apolloClient';
import { getAuthUser } from '../auth/authState';

type UsersListState = {
  users: null | Array<User> | ErrorCapsule,
}

const userShape = `{
  id
  email
  firstName
  lastName
  avatar {
    id
    cover(width: 100, height: 100) { width height url }
  }
  isAdmin
  isFranchiseeManager
  franchisee { 
    id
    title
  }
  office {
    id
    title
  }
  lastLogin
}`;

const initialState: UsersListState = {
  users: null,
};

const ACTION_SET_USERS = 'users/usersList/ACTION_SET_USERS';
const ACTION_DELETE_USER = 'users/usersList/ACTION_DELETE_USER';

function setUsers(users: null | Array<User> | ErrorCapsule) {
  return {
    type: ACTION_SET_USERS,
    users,
  };
}

async function loadUsers(filter?: ?{ [string]: string }) {
  try {
    const users = await listUsers(filter, userShape);

    return setUsers(users);
  } catch (err) {
    return setUsers(new ErrorCapsule(err, () => [
      setUsers(initialState.users),
      loadUsers(filter),
    ]));
  }
}

async function deleteUser(id) {
  await apolloMutate(
    gql`
      mutation ($id: String!) {
        deleteUser(id: $id)
      }
    `,
    { id }
  );

  return {
    type: ACTION_DELETE_USER,
    id,
  };
}

export function usersListReducer(
  state: UsersListState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_USERS:
      return {
        ...state,
        users: action.users,
      };

    case ACTION_DELETE_USER:
      return {
        ...state,
        users: _filter(state.users, ({ id }) => id !== action.id),
      };

    default:
      return state;
  }
}

type Props = {}

function UsersListPage({}: Props) {
  const dispatch = useDispatch();
  const {
    users,
  }: UsersListState = useSelector(({ users: module }) => module.usersList);
  const {
    isAdmin,
  }: User = useSelector(getAuthUser);

  // Load Users
  useEffect(() => {
    dispatch([
      setUsers(null),
      loadUsers({ isDeleted: false }),
    ]);
    return () => dispatch(setUsers(null));
  }, []);

  // Render
  return (
    <UsersListPageView
      isAdminUi={isAdmin}
      users={users}
      onDeleteUser={(id) => dispatch(deleteUser(id))}
    />
  );
}

export function usersListPageRoutes(): Routes {
  return [
    {
      title: 'Пользователи',
      path: '/',
      isEnabled: ({ isUser }) => isUser,
      nav: {
        isWide: true,
      },
      render: params => (
        <UsersListPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
