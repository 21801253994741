// @flow
import React from 'react';

export type Props = {}

export default function LoaderView({}: Props) {
  return (
    <div className="loader">
      <div className="loader__animation" />
      <div className="loader__underlay" />
    </div>
  );
}
