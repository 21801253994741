// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { renderSwitch } from 'metaup/render/visualUtils';
import { bem, renderImageMeta } from '../../core/render/visualUtils';
import type { User } from '../../users/model/User.model';
import SearchDeviceWidget from '../../frontdesk/SearchDeviceWidget';
import SelectPrinterWidget from '../../frontdesk/SelectPrinterWidget';

export type HeaderWidgetViewProps = {
  user: ?User,
  searchDeviceWidgetProps: any,
  selectPrinterWidgetProps: any,
  onLogout: () => void,
}

function getUserKind(user: ?User) {
  if (!user) return 'guest';
  if (user.isAdmin) return 'admin';
  if (user.isFranchiseeManager) return 'franchiseeManager';
  return 'cutter';
}

export default function HeaderWidgetView({
  user,
  searchDeviceWidgetProps,
  selectPrinterWidgetProps,
  onLogout,
}: HeaderWidgetViewProps) {
  const { t } = useTranslation();

  const userKind = getUserKind(user);

  return (
    <div className={bem('top-bar', { preview: !!process.env.REACT_APP_MARK_PREVIEW })}>

      <div className={bem('top-bar', 'desktop') + ' d-flex justify-content-between align-items-center'}>
        <div className={bem('top-bar', 'logo')}>
          <img src="/img/layout/logo.png" width="80" height="21" alt="Protec Cutter" />
        </div>

        <div className="flex-grow-1">
          {renderSwitch(userKind, {
            guest: () => null,
            cutter: () => (
              <div className="d-flex mt-3 mb-3">
                <div className="flex-grow-1">
                  <SearchDeviceWidget {...searchDeviceWidgetProps} />
                </div>
                <div className="w-25 pl-3"><SelectPrinterWidget {...selectPrinterWidgetProps} /></div>
              </div>
            ),
            franchiseeManager: () => (
              <>
                <NavLink to="/stats/sales/" className={bem('top-bar', 'all-events')}>
                  Статистика
                </NavLink>
                <NavLink to="/users/" className={bem('top-bar', 'all-events')}>
                  Пользователи
                </NavLink>
              </>
            ),
            admin: () => (
              <>
                <NavLink to="/stats/sales/" className={bem('top-bar', 'all-events')}>
                  Статистика
                </NavLink>
                <NavLink to="/library/brands/" className={bem('top-bar', 'all-events')}>
                  Лекала
                </NavLink>
                <NavLink to="/library/news/" className={bem('top-bar', 'all-events')}>
                  Новости
                </NavLink>
                <NavLink to="/print/printers/" className={bem('top-bar', 'all-events')}>
                  Плоттеры
                </NavLink>
                <NavLink to="/org/offices/" className={bem('top-bar', 'all-events')}>
                  Офисы
                </NavLink>
                <NavLink to="/org/franchisees/" className={bem('top-bar', 'all-events')}>
                  Франчайзи
                </NavLink>
                <NavLink to="/users/" className={bem('top-bar', 'all-events')}>
                  Пользователи
                </NavLink>
              </>
            ),
          })}
        </div>

        {user
          ? (
            <>
              {user.avatar
                ? renderImageMeta(user.avatar.square, '', bem('top-bar', 'profile'))
                : (
                  <div className={bem('top-bar', 'profile')}>
                    <svg enableBackground="new 0 0 32 32" height="47px" version="1.1" viewBox="0 0 32 32" width="47px"><g><path d="M22.417,14.836c-1.209,2.763-3.846,5.074-6.403,5.074c-3.122,0-5.39-2.284-6.599-5.046   c-7.031,3.642-6.145,12.859-6.145,12.859c0,1.262,0.994,1.445,2.162,1.445h10.581h10.565c1.17,0,2.167-0.184,2.167-1.445   C28.746,27.723,29.447,18.479,22.417,14.836z" fill="#515151" /><path d="M16.013,18.412c3.521,0,6.32-5.04,6.32-9.204c0-4.165-2.854-7.541-6.375-7.541   c-3.521,0-6.376,3.376-6.376,7.541C9.582,13.373,12.491,18.412,16.013,18.412z" fill="#515151" /></g></svg>
                  </div>
                )
              }
              <button type="button" className="btn btn-link ml-2" onClick={e => onLogout()}>
                выход
              </button>
            </>
          )
          : (
            <>
              <NavLink to="/login/" className={'btn btn-primary ' + bem('top-bar', 'all-events')}>
                Вход
              </NavLink>
            </>
          )
        }
      </div>
    </div>
  );
}
