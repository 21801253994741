// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Brand } from '../model/Brand.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { renderImageMeta } from '../../core/render/visualUtils';
import { useDialog } from '../../elements/Dialog';

export type BrandsListPageViewProps = {
  brands: null | Array<Brand> | ErrorCapsule,
  onDeleteBrand: (id: string) => void,
}

export default function BrandsListPageView({
  brands,
  onDeleteBrand,
}: BrandsListPageViewProps) {
  const { t } = useTranslation();
  const { confirm } = useDialog();

  if (!brands) return <Loader />;
  if (brands instanceof ErrorCapsule) return <LoadFailed error={brands} />;

  return (
    <div className="brands-list">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">Бренды</li>
        </ol>
      </nav>
      <h1>Управление брендами</h1>

      <div className="mb-1 text-right">
        <Link
          to="/library/brand-add/"
          className="btn btn-primary btn-sm"
        >
          добавить бренд
        </Link>
      </div>
      <table className="table">
        <tbody>
          {brands.map(({
            id,
            logo,
            name,
          }) => (
            <tr key={id} className="brands-list__brand">
              <td className="text-center w-25">{logo ? renderImageMeta(logo.fit) : null}</td>
              <td className="align-middle"><Link to={`/library/devices/${id}/`}>{name}</Link></td>
              <td className="align-middle w-25">
                <Link to={`/library/brand-edit/${id}/`} className="btn btn-primary btn-sm">изменить</Link> &nbsp;
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={e => confirm({
                    renderBody: () => `Удаляем ${name}?`,
                    handler: () => onDeleteBrand(id),
                  })}
                >
                  удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
