// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { NewsArticle } from './model/NewsArticle.model';
import {
  getNewsArticle,
  createNewsArticle,
  updateNewsArticle,
  filterNewsArticleInput,
} from './model/NewsArticle.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import NewsEditPageView from './views/NewsEditPageView';

type NewsEditState = {
  newsArticle: null | NewsArticle | ErrorCapsule,
}

const newsArticleShape = `{
  id
  date
  text
}`;

const initialState: NewsEditState = {
  newsArticle: null,
};

const ACTION_SET_NEWS_ARTICLE = 'library/newsEdit/ACTION_SET_NEWS_ARTICLE';

function setNewsArticle(newsArticle: null | NewsArticle | ErrorCapsule) {
  return {
    type: ACTION_SET_NEWS_ARTICLE,
    newsArticle,
  };
}

async function loadNewsArticle(id: string) {
  try {
    const newsArticle = await getNewsArticle(id, newsArticleShape);

    return setNewsArticle(newsArticle);
  } catch (err) {
    return setNewsArticle(new ErrorCapsule(err, () => [
      setNewsArticle(initialState.newsArticle),
      loadNewsArticle(id),
    ]));
  }
}

export async function saveNewsArticle(values) {
  const newsArticle = !values.id
    ? await createNewsArticle(filterNewsArticleInput(values), newsArticleShape)
    : await updateNewsArticle(filterNewsArticleInput(values), newsArticleShape);

  return [
    setNewsArticle(newsArticle),
    redirect('/library/news/'),
  ];
}

export function newsEditReducer(
  state: NewsEditState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_NEWS_ARTICLE:
      return {
        ...state,
        newsArticle: action.newsArticle,
      };

    default:
      return state;
  }
}

type Props = {
  id?: string,
}

function NewsEditPage({
  id,
}: Props) {
  const dispatch = useDispatch();
  const { newsArticle }: NewsEditState = useSelector(({ library }) => library.newsEdit);

  // Load NewsArticle
  useEffect(() => {
    dispatch(id
      ? [
        setNewsArticle(null),
        loadNewsArticle(id),
      ]
      : setNewsArticle({
        date: moment().format('YYYY-MM-DD'),
      }));
    return () => dispatch(setNewsArticle(null));
  }, []);

  // Render
  return (
    <NewsEditPageView
      newsArticle={newsArticle}
      onSubmit={(values: NewsArticle) => dispatch(saveNewsArticle(values))}
    />
  );
}

export function newsEditPageRoutes(): Routes {
  return [
    {
      title: 'Добавление новости',
      path: '/news-add/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <NewsEditPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
    {
      title: 'Редактирование новости',
      path: '/news-edit/:id/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <NewsEditPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
