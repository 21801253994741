// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { Printer } from '../model/Printer.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Cut } from '../model/Cut.model';
import CutStatusWidget from '../CutStatusWidget';

export type PrinterPageViewProps = {
  printer: null | Printer | ErrorCapsule,
  testCut: null | Cut | ErrorCapsule,
  onTestCut: (printerId: string) => void,
}

export default function PrinterPageView({
  printer,
  testCut,
  onTestCut,
}: PrinterPageViewProps) {
  const { t } = useTranslation();

  if (!printer) return <Loader />;
  if (printer instanceof ErrorCapsule) return <LoadFailed error={printer} />;

  const {
    id,
    name,
    model,
    commandsVersion,
    printUpc,
    printSpeed,
    printPressure,
    printRepeats,
  } = printer;

  return (
    <div className="printer">
      <h1>Принтер {name}</h1>
      <table className="table">
        <tbody>
          <tr><th>Модель драйвера</th><td>{model}</td></tr>
          <tr><th>Команды</th><td>{commandsVersion}</td></tr>
          <tr>
            <th>Разрешение</th>
            <td>{printUpc} точек на см ({printUpc * 2.54} dpi, {printUpc / 100}x от лекал)</td>
          </tr>
          <tr><th>Скорость</th><td>{printSpeed}</td></tr>
          <tr><th>Нажим</th><td>{printPressure}</td></tr>
          <tr><th>Повторы</th><td>{printRepeats}</td></tr>
        </tbody>
      </table>
      <div className="d-flex align-items-center mb-5">
        <Link
          to={`/print/printers/${id}/edit/`}
          className="btn btn-outline-primary mr-4"
        >
          изменить настройки
        </Link>
        <button
          type="button"
          className="btn btn-success mr-4"
          onClick={e => onTestCut(id)}
        >
          тестовый рез
        </button>
        {testCut && (
          testCut instanceof ErrorCapsule
            ? <LoadFailed error={testCut} />
            : <CutStatusWidget id={testCut.id} />
        )}
      </div>
    </div>
  );
}
