// @flow
import React, { useCallback, useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import gql from 'graphql-tag';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import _filter from 'lodash/filter';
import _omit from 'lodash/omit';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import VariantsListPageView from './views/VariantsListPageView';
import type { Variant } from './model/Variant.model';
import { apolloMutate, apolloQuery } from '../core/data/apolloClient';
import type { Brand } from './model/Brand.model';
import type { Device } from './model/Device.model';
import { createVariant } from './model/Variant.client';
import { decodeException } from '../elements/LoadFailed';

type VariantsListState = {
  variants: null | Array<Variant> | ErrorCapsule,
  brand: ?Brand,
  device: ?Device,
  actionErrors: ?string,
}

const variantShape = `{
  id
  name
  preview {
    id
    fit(width: 100, height: 100) { width height url }
  }
  guide
  notes
  isEnabled
}`;

const initialState: VariantsListState = {
  variants: null,
  brand: null,
  device: null,
};

const ACTION_SET_VARIANTS = 'library/variantsList/ACTION_SET_VARIANTS';
const ACTION_SET_DEVICE = 'library/devicesList/ACTION_SET_DEVICE';
const ACTION_SET_ACTION_ERRORS = 'library/devicesList/ACTION_SET_ACTION_ERRORS';
const ACTION_DELETE_VARIANT = 'library/variantsList/ACTION_DELETE_VARIANT';

function setVariants(variants: null | Array<Variant> | ErrorCapsule) {
  return {
    type: ACTION_SET_VARIANTS,
    variants,
  };
}

function setDevice(brand: ?Brand, device: ?Device) {
  return {
    type: ACTION_SET_DEVICE,
    brand,
    device,
  };
}

function setActionErrors(actionErrors: ?string) {
  return {
    type: ACTION_SET_ACTION_ERRORS,
    actionErrors,
  };
}

async function loadVariants(deviceId: string) {
  try {
    const device = await apolloQuery(gql` query ($id: String!) {
      getDevice(id: $id) {
        id
        name
        brand {
          id
          name
        }
        variants(filter: { _sort: "name" }) ${variantShape}
      }
    } `, {
      id: deviceId,
    });

    return [
      setDevice(device.brand, _omit(device, ['brand', 'variants'])),
      setVariants(device.variants),
    ];
  } catch (err) {
    return setVariants(new ErrorCapsule(err, () => [
      setDevice(initialState.brand, initialState.device),
      setVariants(initialState.variants),
      loadVariants(deviceId),
    ]));
  }
}

async function deleteVariant(id) {
  await apolloMutate(
    gql`
      mutation ($id: String!) {
        deleteVariant(id: $id)
      }
    `,
    { id }
  );

  return {
    type: ACTION_DELETE_VARIANT,
    id,
  };
}

const previewExtRegExp = /\.(png|jpg)$/;
const cutRegExp = /\.(prn)$/;
const sourceRegExp = /\.(cdr|ai)$/;

async function uploadFiles({ deviceId, acceptedFiles }) {
  let cut = null;
  let preview = null;
  let source = null;
  const errors = [];

  acceptedFiles.forEach(file => {
    if (cutRegExp.test(file.name)) {
      if (cut) {
        errors.push('Загружены два файла резов (PRN), ожидаем один за раз');
      } else {
        cut = file;
      }
    } else if (previewExtRegExp.test(file.name)) {
      if (preview) {
        errors.push('Загружены две картинки (PNG/JPG), ожидаем одну');
      } else {
        preview = file;
      }
    } else if (sourceRegExp.test(file.name)) {
      if (source) {
        errors.push('Загружены два файла исходника (CDR), ожидаем один за раз');
      } else {
        source = file;
      }
    } else {
      errors.push(`Тип файла ${file.name} не опознан`);
    }
  });

  // Report errors
  if (errors.length) {
    return setActionErrors(errors.join('\n'));
  }

  // Upload
  try {
    await createVariant({
      deviceId,
      name: (cut || preview || source).name.replace(/\.[^.]+$/, ''),
      cut,
      preview,
      source,
      isEnabled: cut != null,
    }, variantShape);

    // Refresh
    return loadVariants(deviceId);
  } catch (e) {
    return setActionErrors(decodeException(e));
  }
}

export function variantsListReducer(
  state: VariantsListState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_VARIANTS:
      return {
        ...state,
        variants: action.variants,
      };

    case ACTION_SET_DEVICE:
      return {
        ...state,
        brand: action.brand,
        device: action.device,
      };

    case ACTION_SET_ACTION_ERRORS:
      return {
        ...state,
        actionErrors: action.actionErrors,
      };

    case ACTION_DELETE_VARIANT:
      return {
        ...state,
        variants: _filter(state.variants, ({ id }) => id !== action.id),
      };

    default:
      return state;
  }
}

type Props = {
  deviceId: string,
}

function VariantsListPage({ deviceId }: Props) {
  const dispatch = useDispatch();
  const state = useSelector(({ library }) => library.variantsList);

  // Load data
  useEffect(() => {
    dispatch(loadVariants(deviceId));
    return () => dispatch(setVariants(initialState.variants));
  }, [deviceId]);

  // Enable DnD
  const onDragEnter = useCallback(() => {
    dispatch(setActionErrors(null));
  }, []);
  const onDrop = useCallback(acceptedFiles => {
    dispatch(uploadFiles({ deviceId, acceptedFiles }));
  }, [deviceId]);
  const { getRootProps, isDragActive } = useDropzone({ onDragEnter, onDrop, noKeyboard: true });

  // Render
  return (
    <VariantsListPageView
      {...state}
      rootProps={getRootProps()}
      isDragActive={isDragActive}
      onDeleteVariant={(id) => dispatch(deleteVariant(id))}
    />
  );
}

export function variantsListPageRoutes(): Routes {
  return [
    {
      title: 'Варианты резов',
      path: '/variants/:deviceId/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <VariantsListPage
          {...sanitizeRouteParams(params, {
            deviceId: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
