// @flow
import React from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import _trim from 'lodash/trim';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { supportMocking } from 'metaup/dev/supportMocking';

import SearchDeviceWidgetView from './views/SearchDeviceWidgetView';
import type { Brand } from '../library/model/Brand.model';
import { redirect } from '../core/data/router.redux';
import type { Device } from '../library/model/Device.model';

type SearchDeviceState = {
  searchTerm: string,
  expanded: boolean,
  selectedIndex: number,
}

const initialState: SearchDeviceState = {
  searchTerm: '',
  expanded: false,
  selectedIndex: 0,
};

const ACTION_UPDATE_STATE = 'frontdesk/searchDevice/ACTION_UPDATE_STATE';

function setState(diff) {
  return {
    type: ACTION_UPDATE_STATE,
    diff,
  };
}

export function searchDeviceReducer(
  state: SearchDeviceState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_UPDATE_STATE:
      return {
        ...state,
        ...action.diff,
      };

    default:
      return state;
  }
}

type Props = {};

function SearchDeviceWidget({}: Props) {
  // Get state
  const dispatch = useDispatch();
  const {
    searchTerm,
    expanded,
    selectedIndex,
  } = useSelector(({ frontdesk }) => frontdesk.searchDevice);
  const brands: Array<Brand> = useSelector(({ frontdesk }) => frontdesk.cut.data);

  // Filter
  const searchTerms = _filter(_trim(searchTerm).toLowerCase().split(/\s+/));
  const devices = [];
  if (expanded && searchTerms.length) {
    brands.forEach((brand: Brand) => {
      brand.devices.forEach((device: Device) => {
        const doesNotMatch = _find(
          searchTerms,
          term => (
            device.name.toLowerCase().indexOf(term) === -1
            && brand.name.toLowerCase().indexOf(term) === -1
          )
        );
        if (!doesNotMatch) {
          devices.push({ ...device, brand });
        }
      });
    });
  }

  const effectiveSelectedIndex = selectedIndex < devices.length ? selectedIndex : 0;
  const selectedDevice: Device = devices[effectiveSelectedIndex] || null;

  // Render
  return (
    <SearchDeviceWidgetView
      searchTerm={searchTerm}
      devices={devices}
      expanded={devices.length > 0}
      selectedDeviceId={selectedDevice ? selectedDevice.id : null}
      onSetExpanded={value => dispatch(setState({ expanded: value }))}
      onType={value => dispatch(setState({ searchTerm: value }))}
      onMove={delta => dispatch(setState({
        selectedIndex: (devices.length + effectiveSelectedIndex + delta) % devices.length,
      }))}
      onEnter={() => {
        if (selectedDevice) {
          dispatch([
            setState({ searchTerm: '' }),
            redirect(`/frontdesk/${selectedDevice.brand.id}/${selectedDevice.id}/`),
          ]);
        }
      }}
    />
  );
}

export default supportMocking(SearchDeviceWidget, SearchDeviceWidgetView);
