// @flow
import { required } from 'redux-form-validators';
import { string, image } from '../../core/data/rules';
import type { Device } from './Device.model';
import type { MetaModel } from '../../core/data/rules';

export type Brand = {|
  id: string,
  name: string,
  logo: ImageField,
  devices: [Device],
|}


export const brandMetaModel: MetaModel = {
  name: 'Brand',
  rules: {
    name: [string(), required()],
    logo: [image()],
  },
  labels: {
    name: 'Название',
    logo: 'Логотип',
  },
};
