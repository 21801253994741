// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { cutReducer } from './CutPage';
import { searchDeviceReducer } from './SearchDeviceWidget';
import { selectPrinterReducer } from './SelectPrinterWidget';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'frontdesk';

export const reducer = safeCombineReducers({
  cut: cutReducer,
  searchDevice: searchDeviceReducer,
  selectPrinter: selectPrinterReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
