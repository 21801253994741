// @flow
import { reducer as formReducer } from 'redux-form';
import { createReduxStore } from '../data/reduxStore';

import * as routerRedux from '../data/router.redux';
import * as authRedux from '../../auth/auth.redux';
import * as usersRedux from '../../users/users.redux';
import * as elementsRedux from '../../elements/elements.redux';
import * as printRedux from '../../print/print.redux';
import * as libraryRedux from '../../library/library.redux';
import * as frontdeskRedux from '../../frontdesk/frontdesk.redux';
import * as staticsRedux from '../../statics/statics.redux';
import * as orgRedux from '../../org/org.redux';
import * as statsRedux from '../../stats/stats.redux';

export function assembleRedux() {
  return createReduxStore(
    null,
    { // Libraries
      form: formReducer,
    },
    [ // Modules
      routerRedux,
      authRedux,
      elementsRedux,
      frontdeskRedux,
      libraryRedux,
      orgRedux,
      printRedux,
      staticsRedux,
      statsRedux,
      usersRedux,
    ],
    [ // Listeners
    ]
  );
}
