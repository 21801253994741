// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { usersListReducer } from './UsersListPage';
import { userEditReducer } from './UserEditPage';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'users';

export const reducer = safeCombineReducers({
  usersList: usersListReducer,
  userEdit: userEditReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
