// @flow
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { userMetaModel } from './User.model';

export function filterUserInput(values) {
  return _pickBy(values, (_, key) => key in userMetaModel.rules || key === 'id' || key === 'password');
}

export function listUsers(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: UsersFilter) {
        listUsers(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getUser(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getUser(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createUser(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: UserCreate!) {
        createUser(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updateUser(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: UserUpdate!) {
        updateUser(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
