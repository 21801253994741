// @flow
import { homeRoutes } from './HomePage';
// ### GENERATED IMPORTS GO HERE ###

export function staticsRoutes() {
  return [
    homeRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ];
}
