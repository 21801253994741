// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import _filter from 'lodash/filter';
import _omit from 'lodash/omit';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import DevicesListPageView from './views/DevicesListPageView';
import type { DevicesListPageViewProps } from './views/DevicesListPageView';
import type { Device } from './model/Device.model';
import { apolloMutate, apolloQuery } from '../core/data/apolloClient';
import type { Brand } from './model/Brand.model';

type DevicesListState = {
  devices: null | Array<Device> | ErrorCapsule,
  brand: ?Brand,
}

const deviceShape = `{
  id
  deviceType { id name }
  name
  preview {
    id
    fit(width: 100, height: 100) { width height url }
  }
}`;

const initialState: DevicesListState = {
  devices: null,
  brand: null,
};

const ACTION_SET_DEVICES = 'library/devicesList/ACTION_SET_DEVICES';
const ACTION_SET_BRAND = 'library/devicesList/ACTION_SET_BRAND';
const ACTION_DELETE_DEVICE = 'library/devicesList/ACTION_DELETE_DEVICE';

function setDevices(devices: null | Array<Device> | ErrorCapsule) {
  return {
    type: ACTION_SET_DEVICES,
    devices,
  };
}

function setBrand(brand: ?Brand) {
  return {
    type: ACTION_SET_BRAND,
    brand,
  };
}

async function loadDevices(brandId: string) {
  try {
    const brand = await apolloQuery(gql` query ($id: String!) {
      getBrand(id: $id) {
        id
        name
        devices(filter: { _sort: "name" }) ${deviceShape}
      }
    } `, {
      id: brandId,
    });

    return [
      setBrand(_omit(brand, ['devices'])),
      setDevices(brand.devices),
    ];
  } catch (err) {
    return setDevices(new ErrorCapsule(err, () => [
      setBrand(initialState.brand),
      setDevices(initialState.devices),
      loadDevices(brandId),
    ]));
  }
}

async function deleteDevice(id) {
  await apolloMutate(
    gql`
      mutation ($id: String!) {
        deleteDevice(id: $id)
      }
    `,
    { id }
  );

  return {
    type: ACTION_DELETE_DEVICE,
    id,
  };
}

export function devicesListReducer(
  state: DevicesListState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_DEVICES:
      return {
        ...state,
        devices: action.devices,
      };

    case ACTION_SET_BRAND:
      return {
        ...state,
        brand: action.brand,
      };

    case ACTION_DELETE_DEVICE:
      return {
        ...state,
        devices: _filter(state.devices, ({ id }) => id !== action.id),
      };

    default:
      return state;
  }
}

const DevicesListPage = connect(
  ({ library }): DevicesListPageViewProps => ({
    brand: library.devicesList.brand,
    devices: library.devicesList.devices,
  }),
)(class extends Component<DevicesListPageViewProps> {
  constructor(props) {
    const { brandId, dispatch } = props;
    super(props);

    dispatch(loadDevices(brandId));
  }

  render() {
    const { dispatch } = this.props;
    return (
      <DevicesListPageView
        {...this.props}
        onDeleteDevice={(id) => dispatch(deleteDevice(id))}
      />
    );
  }
});

export function devicesListPageRoutes(): Routes {
  return [
    {
      title: 'Модели',
      path: '/devices/:brandId/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <DevicesListPage
          {...sanitizeRouteParams(params, {
            brandId: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
