// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { NewsArticle } from '../model/NewsArticle.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';

export type NewsWidgetViewProps = {
  newsArticles: null | Array<NewsArticle> | ErrorCapsule,
}

export default function NewsWidgetView({
  newsArticles,
}: NewsWidgetViewProps) {
  const { t } = useTranslation();

  if (!newsArticles) return <Loader />;
  if (newsArticles instanceof ErrorCapsule) return <LoadFailed error={newsArticles} />;

  return (
    <div className="news">
      <h3 className="news__title mb-3">Новости</h3>
      {newsArticles.map(({
        id,
        date,
        text,
      }) => (
        <div key={id} className="news__news-article mb-3">
          <div className="text-muted">{moment(date).format('DD.MM.YYYY')}</div>
          <div className="news__text">{text}</div>
        </div>
      ))}
    </div>
  );
}
