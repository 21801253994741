// @flow
import React, { ChildrenArray, React$Element } from 'react';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _trim from 'lodash/trim';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import type { ImageMeta } from '../data/rules';
import ErrorCapsule from '../exceptions/ErrorCapsule';

export type ReactChildren = ChildrenArray<React$Element<any>>;

export function bem(
  block: string,
  elementOrBlockModifiers: ?string | { [string]: boolean | string } = null,
  elementModifiers: { [string]: boolean | string } = {},
): string {
  let base = block;
  let modifiers;

  if (elementOrBlockModifiers === null) {
    return base;
  }
  if (_isObject(elementOrBlockModifiers)) {
    modifiers = elementOrBlockModifiers;
  } else {
    base += '__' + elementOrBlockModifiers;
    modifiers = elementModifiers;
  }

  let result = base;
  for (const modifier in modifiers) { // eslint-disable-line no-restricted-syntax
    const value = modifiers[modifier];
    if (value) {
      result += ' ' + base + '_' + modifier;
      if (_isString(value)) {
        result += '_' + value;
      }
    }
  }

  return result;
}

type BemContextFn = (
  elementOrBlockModifiers?: ?string | { [string]: boolean | string },
  elementModifiers?: { [string]: boolean | string },
) => string;

export function bemBlock(block: string): BemContextFn {
  return (elementOrBlockModifiers, elementModifiers) => (
    bem(block, elementOrBlockModifiers, elementModifiers)
  );
}

export function formatMultiline(value) {
  return _trim(value)
    .split(/\n *\n/g)
    .map(paragraph => (
      <p style={{ whiteSpace: 'pre-line' }}>{paragraph}</p>
    ));
}

export function formatHtml(value) {
  return <div dangerouslySetInnerHTML={{ __html: value }} />;
}

/**
 * This is a design time util. Do not translate
 */
export function renderNested(value) {
  if (_isObject(value)) {
    if (value.title) return value.title;
    if (value.subject) return value.subject;
    if (value.name) return value.name;
    if (value.email) return value.email;
    if (value.id) return value.id;
    const keys = Object.keys(value);
    return keys.length ? `${keys[0]}: ${value[keys[0]]}` : 'Empty';
  }
  if (value == null) {
    return 'None';
  }
  return value;
}

export function renderImageMeta(value: ?ImageMeta, alt: string, className?: ?string): React$Node {
  return value && (
    <img
      src={value.url}
      width={value.width}
      height={value.height}
      alt={alt}
      className={className}
    />
  );
}

export function analyseLoaded(...values): boolean | ErrorCapsule {
  const errorCapsule = _find(values, value => value instanceof ErrorCapsule);
  if (errorCapsule) {
    return errorCapsule;
  }
  return _findIndex(values, value => value == null) === -1;
}

export function cleanUpIpAddress(ip) {
  // Extract v4 from v6
  const matches = ip.match(/:(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})\$/);
  if (matches) {
    return matches[1];
  }
  return ip;
}

export function announceFileIsUploaded(value) {
  return (
    <>
      {value ? <><span className="text-success ml-1 mr-1">✔</span>Файл уже загружен. Выберите замену, если требуется</> : null}
    </>
  );
}
