// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  message: string,
  onRetry?: Function,
}

export default function LoadFailedView({ message, onRetry }: Props) {
  const { t } = useTranslation();

  return (
    <div className="alert alert-danger">
      <p>
        <strong>{t('Load failed.')}</strong>
        {onRetry && (
          <button type="button" className="btn btn-link" onClick={onRetry}>{t('Click to retry')}</button>
        )}
      </p>
      <p>{message}</p>
    </div>
  );
}
