// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';
import { cutPageRoutes } from './CutPage';
// ### GENERATED IMPORTS GO HERE ###

export function frontdeskRoutes() {
  return prefixRoutes('/frontdesk/', [
    cutPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
