// @flow
import uuid from 'uuid/v4';

let errorHandler = (error, errorId) => {
  console.error('#' + errorId, error);
};

export function setErrorHandler(fn: (Error, errorId: string) => void) {
  errorHandler = fn;
}

function newErrorId() {
  return uuid();
}

export function asyncErrorHandler(promise: Promise<any>) {
  promise.catch(error => {
    errorHandler(error, newErrorId());
  });
}

export function handleError(error: any): string {
  const errorId = newErrorId();
  errorHandler(error, errorId);
  return errorId;
}

export function logErrorNoReport(error: any): string {
  console.error('Got an error, not reporting:', error);
}
