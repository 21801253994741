// @flow
import React from 'react';
import { required, email } from 'redux-form-validators';
import { useTranslation } from 'react-i18next';

import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
} from '../../elements/DefaultForm';
import type { AuthLogin } from '../model/AuthLogin.model';
import type { MetaModel } from '../../core/data/rules';
import { string } from '../../core/data/rules';

export type LoginPageViewProps = {
  authLogin: null | AuthLogin,
  onSubmit: (values: { [string]: any }) => void,
}

export const authLoginMetaModel: MetaModel = {
  rules: {
    email: [string(), required(), email()],
    password: [string(), required()],
  },
  labels: {
    email: 'E-mail',
    password: 'Пароль',
  },
};

export default function LoginPageView({
  authLogin,
  onSubmit,
}: LoginPageViewProps) {
  const { t } = useTranslation();

  return (
    <div className="login">
      <h1>Вход</h1>
      <DefaultForm
        form="login"
        metaModel={authLoginMetaModel}
        initialValues={authLogin}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormText attribute="email" type="email" autoFocus />
        <DefaultFormText attribute="password" type="password" />
        <DefaultFormSubmit label="Войти" />
      </DefaultForm>
    </div>
  );
}
