// @flow
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import type { RouteAuth, RouteDef } from 'metaup/routing/routingUtils';

import Router from '../core/routing/Router';
import MainLayoutView from './views/MainLayoutView';
import { appRoutes } from '../core/assemble/appRoutes';
import { logout } from '../auth/authState';

function getPropsFromRoute(
  t,
  profileMenuOpened,
  setProfileMenuOpened,
  { title, nav = {} }: RouteDef,
  { params },
  { authState }: RouteAuth,
  dispatch
) {
  const { topBar = true, eventMenu = false } = nav;
  return {
    topBar: topBar ? {
      user: authState.user ? authState.user.user : null,
      onLogout: () => dispatch(logout()),
    } : null,
    eventMenu: eventMenu ? { eventId: params.id } : null,
    profileMenu: profileMenuOpened ? {
      profile: authState.user.user,
      onClose: () => setProfileMenuOpened(false),
    } : null,
  };
}

function bodyListener(setProfileMenuOpened, e) {
  // Close profile menu on click outside
  if (!e.target.closest('.profile-menu')) {
    setProfileMenuOpened(false);
  }
  // Close profile menu on nav link click, but let it work first
  if (e.target.closest('a')) {
    setTimeout(() => setProfileMenuOpened(false));
  }
}

export default function MainLayout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Handle ProfileMenu opening
  const [profileMenuOpened, setProfileMenuOpened] = useState(false);
  useEffect(() => {
    if (!profileMenuOpened) {
      return undefined;
    }
    const listener = bodyListener.bind(null, setProfileMenuOpened);
    document.body.addEventListener('click', listener);
    return () => {
      document.body.removeEventListener('click', listener);
    };
  }, [profileMenuOpened]);

  // Handle window width
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const resize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);


  let View = MainLayoutView;
  let overrideWidth = width;

  // Design-time injection
  if (process.env.REACT_APP_DESIGN_LAYOUT === 'yes') {
    overrideWidth = 1600;
    View = require('metaup/designLayout/DesignLayoutView') // eslint-disable-line global-require
      .wrap(MainLayoutView, appRoutes, {
        designWidth: overrideWidth,
        showDesignCol: false,
      });
  }

  return (
    <Router
      handler={(route, match, auth) => (
        <View
          {...getPropsFromRoute(
            t,
            profileMenuOpened,
            setProfileMenuOpened,
            route,
            match,
            auth,
            dispatch
          )}
          width={overrideWidth}
          route={route}
          auth={auth}
        >
          {route.render(match.params, auth)}
        </View>
      )}
    />
  );
}
