// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { variantMetaModel } from './Variant.model';

export function filterVariantInput(values) {
  return _pickBy(values, (_, key) => key in variantMetaModel.rules || key === 'id');
}

export function listVariants(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: VariantsFilter) {
        listVariants(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getVariant(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getVariant(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createVariant(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: VariantCreate!) {
        createVariant(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updateVariant(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: VariantUpdate!) {
        updateVariant(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
