// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';
import { printersListPageRoutes } from './PrintersListPage';
import { printerPageRoutes } from './PrinterPage';
import { addPrinterPageRoutes } from './AddPrinterPage';
import { editPrinterPageRoutes } from './EditPrinterPage';
// ### GENERATED IMPORTS GO HERE ###

export function printRoutes() {
  return prefixRoutes('/print/', [
    printersListPageRoutes(),
    printerPageRoutes(),
    addPrinterPageRoutes(),
    editPrinterPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
