// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { renderSwitch } from 'metaup/render/visualUtils';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import UnexpectedCaseException from '../../core/exceptions/UnexpectedCaseException';

export type CutStatusWidgetViewProps = {
  cutStatusUpdate: null | CutStatusUpdate | ErrorCapsule,
}

export default function CutStatusWidgetView({
  cutStatusUpdate,
}: CutStatusWidgetViewProps) {
  const { t } = useTranslation();

  if (!cutStatusUpdate) return <Loader inline />;
  if (cutStatusUpdate instanceof ErrorCapsule) return <LoadFailed error={cutStatusUpdate} />;

  const {
    status,
    statusMessage,
    isPrinterConnected,
  } = cutStatusUpdate;

  const isFinalStatus = (status === 'printed' || status === 'cancelled');

  return (
    <div className="cut-status">
      {(!isPrinterConnected && !isFinalStatus)
        ? <div className="badge badge-warning">Принтер не на связи, проверьте программу-клиент</div>
        : renderSwitch(status, {
          queued: () => <div className="badge badge-info">Подключение</div>,
          sent: () => <div className="badge badge-info">Печатаем</div>,
          printed: () => <div className="badge badge-success">Ушло на плоттер</div>,
          cancelled: () => (
            <div className="badge badge-danger text-white" title={statusMessage}>
              Подключиться не удалось
            </div>
          ),
        }, () => { throw new UnexpectedCaseException() })
      }
    </div>
  );
}
