// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required } from 'redux-form-validators';
import { string } from '../../core/data/rules';
import type { MetaModel } from '../../core/data/rules';

export type DeviceType = {|
  id: string,
  name: string,
|}


export const deviceTypeMetaModel: MetaModel = {
  name: 'DeviceType',
  rules: {
    name: [string(), required()],
  },
};
