// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Printer } from '../../print/model/Printer.model';

export type SelectPrinterWidgetViewProps = {
  officePrinters: null | Array<Printer> | ErrorCapsule,
  selectedPrinter: ?Printer,
  onChange: (id: string) => void,
}

export default function SelectPrinterWidgetView({
  officePrinters,
  selectedPrinter,
  onChange,
}: SelectPrinterWidgetViewProps) {
  const { t } = useTranslation();

  if (!officePrinters) return <Loader />;
  if (officePrinters instanceof ErrorCapsule) return <LoadFailed error={officePrinters} />;

  // No printers
  if (officePrinters.length === 0) {
    return (
      <div className="alert alert-danger">Плоттеры вашего офиса ещё не зарегистрирваны здесь</div>
    );
  }

  // The only
  if (officePrinters.length === 1) {
    return (
      <input
        className="form-control"
        defaultValue={selectedPrinter.name}
        disabled
      />
    );
  }

  // Select
  return (
    <select
      className="select-printer form-control"
      value={selectedPrinter.id}
      onChange={e => onChange(e.target.value)}
    >
      {officePrinters.map(({ id, name }: Printer) => (
        <option key={id} value={id}>{name}</option>
      ))}
    </select>
  );
}
