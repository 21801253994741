// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  DefaultForm, DefaultFormDropDown,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
} from '../../elements/DefaultForm';
import { printerMetaModel } from '../model/Printer.model';
import type { Printer } from '../model/Printer.model';

import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { formValueSelector } from 'redux-form';

export type EditPrinterPageViewProps = {
  printer: null | Printer | ErrorCapsule,
  onSubmit: (values: { [string]: any }) => void,
}

const selectValue = formValueSelector('editPrinter');

export default function EditPrinterPageView({
  printer,
  onSubmit,
}: EditPrinterPageViewProps) {
  const { t } = useTranslation();
  const commandsVersion = useSelector(state => selectValue(state, 'commandsVersion'));

  if (!printer) return <Loader />;
  if (printer instanceof ErrorCapsule) return <LoadFailed error={printer} />;

  const {
    name,
  } = printer;

  return (
    <div className="edit-printer">
      <h1>Настройки принтера {name}</h1>
      <DefaultForm
        form="editPrinter"
        metaModel={printerMetaModel}
        initialValues={printer}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormDropDown
          attribute="commandsVersion"
          options={[['graphtec', 'Graphtec'], ['cameo', 'Cameo']]}
          autoFocus
        />
        <DefaultFormText
          attribute="printUpc"
          type="number"
          hint={commandsVersion === 'cameo'
            ? 'Для Cameo обычно это 200'
            : 'Для Graphtec обычно это 100'
          }
        />
        <div className={commandsVersion === 'graphtec' ? 'p-3 border mb-3' : undefined}>
          {commandsVersion === 'graphtec'
            && (<div className="alert alert-warning">Для Graphtec эти параметры ещё не реализованы</div>)}
          <DefaultFormText attribute="printSpeed" type="number" />
          <DefaultFormText attribute="printPressure" type="number" />
          <DefaultFormText attribute="printRepeats" type="number" />
        </div>
        <DefaultFormSubmit label="Сохранить" />
      </DefaultForm>
    </div>
  );
}
