// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { supportMocking } from 'metaup/dev/supportMocking';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import CutStatusWidgetView from './views/CutStatusWidgetView';
import { onCutStatus } from './model/CutStatusUpdate.client';
import type { CutStatusUpdate } from './model/CutStatusUpdate.model';

type CutStatusState = {
  cutStatusUpdate: null | CutStatusUpdate | ErrorCapsule,
}

const cutStatusUpdateShape = `{
  id
  status
  statusMessage
  isPrinterConnected
}`;

const initialState: CutStatusState = {
  cutStatusUpdate: null,
};

const ACTION_SET_CUT_STATUS_UPDATE = 'print/cutStatus/ACTION_SET_CUT_STATUS_UPDATE';

function setCutStatusUpdate(cutStatusUpdate: null | CutStatusUpdate | ErrorCapsule) {
  return {
    type: ACTION_SET_CUT_STATUS_UPDATE,
    cutStatusUpdate,
  };
}

export function cutStatusReducer(
  state: CutStatusState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_CUT_STATUS_UPDATE:
      return {
        ...state,
        cutStatusUpdate: action.cutStatusUpdate,
      };

    default:
      return state;
  }
}

type Props = {
  id: string,
}

function CutStatusWidget({
  id,
}: Props) {
  const dispatch = useDispatch();
  const {
    cutStatusUpdate,
  }: CutStatusState = useSelector(({ print }) => print.cutStatus);

  // Load CutStatusUpdate
  useEffect(() => {
    dispatch(setCutStatusUpdate(null));
    return onCutStatus(
      id,
      cutStatusUpdateShape,
      value => dispatch(setCutStatusUpdate(value))
    );
  }, [id]);

  // Render
  return (
    <CutStatusWidgetView
      cutStatusUpdate={cutStatusUpdate}
    />
  );
}

export default supportMocking(CutStatusWidget, CutStatusWidgetView);
