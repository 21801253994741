// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { bemBlock } from '../../core/render/visualUtils';
import type { Device } from '../../library/model/Device.model';

export type SearchDeviceWidgetViewProps = {
  searchTerm: string,
  devices: Array<Device>,
  expanded: boolean,
  selectedDeviceId: string,
  onSetExpanded: boolean => void,
  onType: string => void,
  onMove: (delta: -1 | 1) => void,
  onEnter: () => void,
}

export default function SearchDeviceWidgetView({
  searchTerm,
  devices,
  expanded,
  selectedDeviceId,
  onSetExpanded,
  onType,
  onMove,
  onEnter,
}: SearchDeviceWidgetViewProps) {
  const { t } = useTranslation();
  const bem = bemBlock('search-device');

  return (
    <div className={bem()}>
      <input
        className={'form-control ' + bem('box')}
        placeholder="Поиск"
        value={searchTerm}
        onFocus={() => onSetExpanded(true)}
        onBlur={() => setTimeout(() => onSetExpanded(false), 100)} // Delay to make Link clicks work
        onChange={e => onType(e.target.value)}
        onKeyDown={e => {
          if (e.keyCode === 38) { onMove(-1); e.preventDefault() }
          if (e.keyCode === 40) { onMove(1); e.preventDefault() }
          if (e.keyCode === 13) { onEnter(); e.preventDefault() }
        }}
      />
      {expanded && (
        <div className={bem('dropdown-wrapper')}>
          <div className={bem('dropdown')}>
            {devices.map(({
              id,
              name,
              preview,
              brand,
            }: Device) => (
              <Link
                to={`/frontdesk/${brand.id}/${id}/`}
                key={id}
                className={
                  'd-flex align-items-center '
                  + bem('item', { selected: selectedDeviceId === id })
                }
              >
                <span className={bem('pic')} style={{ backgroundImage: preview && `url(${preview.fit.url})` }} />
                <span>
                  {brand.name} <strong>{name}</strong>
                </span>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
