// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { officeMetaModel } from './Office.model';

export function filterOfficeInput(values) {
  return _pickBy(values, (_, key) => key in officeMetaModel.rules || key === 'id');
}

export function listOffices(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: OfficesFilter) {
        listOffices(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getOffice(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getOffice(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createOffice(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: OfficeCreate!) {
        createOffice(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updateOffice(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: OfficeUpdate!) {
        updateOffice(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
