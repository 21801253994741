// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { newsArticleMetaModel } from './NewsArticle.model';

export function filterNewsArticleInput(values) {
  return _pickBy(values, (_, key) => key in newsArticleMetaModel.rules || key === 'id');
}

export function listNewsArticles(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: NewsArticlesFilter) {
        listNewsArticles(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getNewsArticle(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getNewsArticle(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createNewsArticle(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: NewsArticleCreate!) {
        createNewsArticle(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updateNewsArticle(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: NewsArticleUpdate!) {
        updateNewsArticle(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
