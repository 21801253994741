// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';
import { franchiseesListPageRoutes } from './FranchiseesListPage';
import { franchiseeEditPageRoutes } from './FranchiseeEditPage';
import { officesListPageRoutes } from './OfficesListPage';
import { officeEditPageRoutes } from './OfficeEditPage';
// ### GENERATED IMPORTS GO HERE ###

export function orgRoutes() {
  return prefixRoutes('/org/', [
    franchiseesListPageRoutes(),
    franchiseeEditPageRoutes(),
    officesListPageRoutes(),
    officeEditPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
