// @flow
import { email, required } from 'redux-form-validators';
import { string, image, boolean, requireWhenNotDeleted } from '../../core/data/rules';
import type { Franchisee } from '../../org/model/Franchisee.model';
import type { Office } from '../../org/model/Office.model';
import type { MetaModel } from '../../core/data/rules';

export type User = {|
  id: string,
  email: ?string,
  firstName: ?string,
  lastName: ?string,
  fathersName: ?string,
  phone: ?string,
  notes: ?string,
  avatar: ?ImageField,
  isFranchiseeManager: boolean,
  isAdmin: boolean,
  franchiseeId: ?string,
  franchisee: ?Franchisee,
  officeId: ?string,
  office: ?Office,
  isDeleted: boolean,
|}


export const userMetaModel: MetaModel = {
  name: 'User',
  rules: {
    email: [requireWhenNotDeleted(), email()],
    firstName: [string()],
    lastName: [string()],
    fathersName: [string()],
    phone: [string()],
    notes: [string()],
    avatar: [image()],
    isFranchiseeManager: [boolean(), required()],
    isAdmin: [boolean(), required()],
    franchiseeId: [string()],
    officeId: [string()],
    isDeleted: [boolean(), required()],
  },
  labels: {
    email: 'E-mail',
    firstName: 'Имя',
    lastName: 'Фамилия',
    fathersName: 'Отчество',
    phone: 'Телефон',
    notes: 'Заметки',
    avatar: 'Изображение',
    isFranchiseeManager: 'Руководитель-франчайзи',
    isAdmin: 'Администратор',
    franchiseeId: 'Франчайзи',
    officeId: 'Офис',
  },
};
