// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { NewsArticle } from '../model/NewsArticle.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { useDialog } from '../../elements/Dialog';

export type NewsListPageViewProps = {
  newsArticles: null | Array<NewsArticle> | ErrorCapsule,
  onDeleteNewsArticle: (id: string) => void,
}

export default function NewsListPageView({
  newsArticles,
  onDeleteNewsArticle,
}: NewsListPageViewProps) {
  const { t } = useTranslation();
  const { confirm } = useDialog();

  if (!newsArticles) return <Loader />;
  if (newsArticles instanceof ErrorCapsule) return <LoadFailed error={newsArticles} />;

  return (
    <div className="news-list">
      <h1>Новости</h1>
      <div className="mb-3 text-right">
        <Link
          to="/library/news-add/"
          className="btn btn-primary btn-sm"
        >
          добавить новость
        </Link>
      </div>
      {newsArticles.map(({
        id,
        date,
        text,
      }) => (
        <div key={id} className="card news-list__news-article mb-4">
          <div className="card-body" style={{ whiteSpace: 'pre-line' }}>
            {text}
          </div>
          <div className="card-footer d-flex justify-content-between align-items-center">
            <div>{moment(date).format('DD.MM.YYYY')}</div>
            <div>
              <Link to={`/library/news-edit/${id}/`} className="btn btn-primary btn-sm">
                изменить
              </Link>
              &nbsp;
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={e => confirm({
                  renderBody: () => `Удаляем новость от ${moment(date).format('DD.MM.YYYY')}?`,
                  handler: () => onDeleteNewsArticle(id),
                })}
              >
                удалить
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
