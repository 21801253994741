// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';
import { usersListPageRoutes } from './UsersListPage';
import { userEditPageRoutes } from './UserEditPage';
// ### GENERATED IMPORTS GO HERE ###

export function usersRoutes() {
  return prefixRoutes('/users/', [
    usersListPageRoutes(),
    userEditPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
