// @flow
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import {
  apolloQuery,
  apolloSubmitForm,
} from '../../core/data/apolloClient';
import { cutMetaModel } from './Cut.model';

export function filterCutInput(values) {
  return _pickBy(values, (_, key) => key in cutMetaModel.rules || key === 'id');
}

export function listCuts(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: CutsFilter) {
        listCuts(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getCut(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getCut(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createCut(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: CutCreate!) {
        createCut(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
