// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import gql from 'graphql-tag';
import _find from 'lodash/find';
import moment from 'moment';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { StatsOnOffice } from './model/StatsOnOffice.model';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import SalesPageView from './views/SalesPageView';
import { apolloQuery } from '../core/data/apolloClient';
import { getAuthUser } from '../auth/authState';
import type { User } from '../users/model/User.model';

type SalesState = {
  statsOnOffices: null | Array<StatsOnOffice> | ErrorCapsule,
  selectedOfficeId: ?string,
  date: string,
}

const statsOnOfficeShape = `{
  office {
    id
    title
  }
  totalCuts
  cuts {
    id
    madeAt
    status
    variant {
      id
      device {
        id
        name
        brand { id name }
      }
      name
    }
  }
}`;

const initialState: SalesState = {
  statsOnOffices: null,
  selectedOfficeId: null,
  date: moment().format('YYYY-MM-DD'),
};

const ACTION_SET_STATE = 'stats/sales/ACTION_SET_STATE';

function setState(diff: SalesState) {
  return {
    type: ACTION_SET_STATE,
    diff,
  };
}

async function loadStatsOnOffices(franchiseeId, date) {
  try {
    const statsOnOffices = await apolloQuery(
      gql`
        query ($franchiseeId: String, $date: String!) {
          getStatsOnOffices(franchiseeId: $franchiseeId, date: $date) ${statsOnOfficeShape}
        }
      `,
      { franchiseeId, date }
    );

    return setState({ statsOnOffices });
  } catch (err) {
    return setState({
      statsOnOffices: new ErrorCapsule(err, () => [
        setState({ statsOnOffices: null }),
        loadStatsOnOffices(franchiseeId, date),
      ]),
    });
  }
}

export function salesReducer(
  state: SalesState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_STATE:
      return {
        ...state,
        ...action.diff,
      };

    default:
      return state;
  }
}

type Props = {
  franchiseeId: string,
}

function SalesPage({ franchiseeId }: Props) {
  const dispatch = useDispatch();
  const {
    statsOnOffices,
    selectedOfficeId,
    date,
  }: SalesState = useSelector(({ stats }) => stats.sales);
  const authUser: User = useSelector(getAuthUser);

  // Load StatsOnOffices
  useEffect(() => {
    dispatch([
      setState({ statsOnOffices: null }),
      loadStatsOnOffices(authUser.franchiseeId || franchiseeId, date),
    ]);
    return () => dispatch(setState({ statsOnOffices: null }));
  }, [date]);

  // Select an office
  const selectedStatsOnOffice = !Array.isArray(statsOnOffices) ? null
    : (
      _find(statsOnOffices, ({ office }) => office.id === selectedOfficeId)
      || statsOnOffices[0]
      || null
    );

  // Render
  return (
    <SalesPageView
      isAdmin={authUser.isAdmin}
      statsOnOffices={statsOnOffices}
      selectedStatsOnOffice={selectedStatsOnOffice}
      onSelectOffice={officeId => dispatch(setState({ selectedOfficeId: officeId }))}
      date={date}
      onSelectDate={newDate => dispatch(setState({ date: newDate }))}
    />
  );
}

export function salesPageRoutes(): Routes {
  return [
    {
      title: 'Статистика резов',
      path: ['/sales/', '/sales/:franchiseeId'],
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <SalesPage
          {...sanitizeRouteParams(params, {
            franchiseeId: '?id',
          }, {
            franchiseeId: null,
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
