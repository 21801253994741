// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { renderSwitch } from 'metaup/render/visualUtils';
import { StatsOnOffice } from '../model/StatsOnOffice.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { bem } from '../../core/render/visualUtils';

export type SalesPageViewProps = {
  isAdmin: boolean,
  statsOnOffices: null | Array<StatsOnOffice> | ErrorCapsule,
  selectedStatsOnOffice: ?StatsOnOffice,
  onSelectOffice: (officeId: string) => void,
  date: string,
  onSelectDate: (newDate: string) => void,
}

function showLoading(object, render) {
  if (!object) return <Loader inline />;
  if (object instanceof ErrorCapsule) return <LoadFailed error={object} />;
  return render();
}

export default function SalesPageView({
  isAdmin,
  statsOnOffices,
  selectedStatsOnOffice,
  onSelectOffice,
  date,
  onSelectDate,
}: SalesPageViewProps) {
  const { t } = useTranslation();

  return (
    <div className="sales">
      {isAdmin && (
        <Link to="/stats/franchisee-sales/" className="float-right mt-3">все франчайзи</Link>
      )}
      <h1>Статистика</h1>

      {Array.isArray(statsOnOffices) && statsOnOffices.length === 0
        ? (
          <div className="alert alert-warning"><Link to="/org/offices/">Офисы</Link> не зарегистрированы</div>
        )
        : (
          <>
            <div className="row mb-3">
              <div className="col-6">
                {showLoading(
                  statsOnOffices,
                  () => (
                    <table className="table table-hover w-auto">
                      <tbody>
                        {statsOnOffices.map(({
                          office: { id, title },
                          totalCuts,
                        }: StatsOnOffice) => (
                          <tr
                            key={id}
                            className={bem('sales', 'office', { selected: id === selectedStatsOnOffice.office.id })}
                            onClick={e => onSelectOffice(id)}
                          >
                            <td>{title}</td>
                            <td className="g__num">{totalCuts}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )
                )}
              </div>
              <div className="col-6">
                <div className="input-group justify-content-center">
                  <div className="input-group-prepend">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => onSelectDate(moment(date).subtract(1, 'day').format('YYYY-MM-DD'))}
                    >
                      &lt;
                    </button>
                  </div>
                  <span className="input-group-text w-50 justify-content-center">
                    {moment(date).format('D MMM YYYY')}
                  </span>
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => onSelectDate(moment(date).add(1, 'day').format('YYYY-MM-DD'))}
                    >
                      &gt;
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                {showLoading(
                  selectedStatsOnOffice,
                  () => (
                    <>
                      <div className="row mb-3">
                        <div className="col-4">
                          <h2>{selectedStatsOnOffice.office.title}</h2>
                          <div className="text-center mt-4">
                            Всего резов за день
                            <div style={{ fontSize: '72px' }}>{selectedStatsOnOffice.totalCuts}</div>
                          </div>
                        </div>

                        <div className="col-8">
                          <table className="table">
                            <tbody>
                              {selectedStatsOnOffice.cuts.map(({
                                id,
                                madeAt,
                                status,
                                variant,
                              }) => (
                                <tr key={id}>
                                  <td>{moment.utc(madeAt).format('HH:mm:ss')}</td>
                                  {variant
                                    ? (
                                      <>
                                        <td>{variant.device.brand.name}</td>
                                        <td>{variant.device.name}</td>
                                        <td>{variant.name}</td>
                                      </>
                                    )
                                    : <td colSpan={3}>Тестовый рез / удалённый вариант</td>
                                  }
                                  <td>
                                    {renderSwitch(status, {
                                      printed: () => 'Напечатано',
                                      cancelled: () => <div className="badge badge-danger">Не удалось</div>,
                                    }, () => <div className="text-muted">в очереди</div>)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          </>
        )
      }
    </div>
  );
}
