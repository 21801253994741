// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { deviceTypeMetaModel } from './DeviceType.model';

export function filterDeviceTypeInput(values) {
  return _pickBy(values, (_, key) => key in deviceTypeMetaModel.rules || key === 'id');
}

export function listDeviceTypes(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: DeviceTypesFilter) {
        listDeviceTypes(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getDeviceType(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getDeviceType(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createDeviceType(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: DeviceTypeCreate!) {
        createDeviceType(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updateDeviceType(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: DeviceTypeUpdate!) {
        updateDeviceType(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
