// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DefaultForm, DefaultFormFile,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
} from '../../elements/DefaultForm';

import type { Brand } from '../model/Brand.model';
import { brandMetaModel } from '../model/Brand.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { announceFileIsUploaded } from '../../core/render/visualUtils';

export type BrandEditPageViewProps = {
  brand: null | Brand | ErrorCapsule,
  onSubmit: (values: { [string]: any }) => void,
}

export default function BrandEditPageView({
  brand,
  onSubmit,
}: BrandEditPageViewProps) {
  const { t } = useTranslation();

  if (!brand) return <Loader />;
  if (brand instanceof ErrorCapsule) return <LoadFailed error={brand} />;

  const isCreating = brand.id == null;
  const pageTitle = isCreating
    ? 'Добавление бренда'
    : `Редактирование ${brand.name}`;

  return (
    <div className="brand-edit">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/library/brands/">Бренды</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">{pageTitle}</li>
        </ol>
      </nav>
      <h1>{pageTitle}</h1>

      <DefaultForm
        form="brandEdit"
        metaModel={brandMetaModel}
        initialValues={brand}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormText attribute="name" autoFocus />
        <DefaultFormFile attribute="logo" hint={announceFileIsUploaded(brand.logo)} />
        <DefaultFormSubmit label={isCreating ? 'Добавить' : 'Изменить'} />
      </DefaultForm>
    </div>
  );
}
