// @flow
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import _filter from 'lodash/filter';
import { Link } from 'react-router-dom';
import type { PrintClientSession } from '../model/PrintClientSession.model';
import Loader from '../../elements/Loader';
import LoadFailed, { decodeException } from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Office } from '../../org/model/Office.model';
import { analyseLoaded, cleanUpIpAddress } from '../../core/render/visualUtils';

export type AddPrinterPageViewProps = {
  sessions: null | Array<PrintClientSession> | ErrorCapsule,
  offices: null | Array<Office> | ErrorCapsule,
  selectedOfficeId: ?string,
  onSelectOffice: (id: string) => void,
  connectError: ?{
    clientId: string,
    printerLocalId: string,
    error: Error,
  },
  onConnect: (clientId: string, printerLocalId: string) => void,
  showKnown: boolean,
  onShowKnown: () => void,
}

export default function AddPrinterPageView({
  sessions,
  offices,
  selectedOfficeId,
  onSelectOffice,
  onConnect,
  connectError,
  showKnown,
  onShowKnown,
}: AddPrinterPageViewProps) {
  const { t } = useTranslation();

  const loaded = analyseLoaded(sessions, offices);
  if (!loaded) return <Loader />;
  if (loaded instanceof ErrorCapsule) return <LoadFailed error={loaded} />;

  const showSessions = showKnown ? sessions
    : _filter(sessions, ({ addedPrinters }) => addedPrinters.length === 0);

  return (
    <div className="add-printer">
      <h1>Добавление плоттера</h1>

      <p>Выполните следующие шаги</p>
      <ol>
        <li>Скачайте <a href={process.env.REACT_APP_PUBLIC_DATA_URL + '/client/ProtecStoreAgentSetup.exe'}>программу</a></li>
        <li>Запустите её</li>
        <li>В списке ниже вы должны увидеть карточку с тем же кодом, который показывает
          программа
        </li>
        <li>В этой карточке нажмите &quot;подключить&quot; для искомого плоттера</li>
      </ol>

      {offices.length
        ? (
          <div className="d-flex align-items-center mb-4">
            <div className="mr-3 text-nowrap">
              Привязать к офису:
            </div>
            <select
              className="form-control"
              value={selectedOfficeId}
              onChange={e => onSelectOffice(e.target.value)}
            >
              {offices.map(({ id, title, franchisee }) => (
                <option key={id} value={id}>
                  {title} @ {franchisee ? franchisee.title : 'Свой офис'}
                </option>
              ))}
            </select>
          </div>
        )
        : (
          <div className="alert alert-warning"><Link to="/org/offices/">Офисы</Link> не зарегистрированы</div>
        )}

      {showSessions.map(({
        id,
        startedAt,
        lostAt,
        ip,
        logExtra,
        clientId,
        clientSessionId,
        availablePrinters,
        addedPrinters,
      }) => (
        <div key={id} className="card add-printer__print-client-session mb-4">
          <div className="card-header d-flex justify-content-between">
            <b title="Код сессии (при каждом запуске он новый)">{clientSessionId}</b>
            <div className="text-muted">
              <span className="badge badge-dark mr-2" title="IP-адрес">{cleanUpIpAddress(ip)}</span>
              <span className="badge badge-dark" title="Код компьютера (привязка к железу)">{clientId}</span>
            </div>
          </div>
          {availablePrinters && availablePrinters.length
            ? (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Принтер</th>
                    <th>Модель</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {availablePrinters.map(({ id: printerLocalId, name, model }) => (
                    <Fragment key={printerLocalId}>
                      <tr>
                        <td>{name}</td>
                        <td>{model}</td>
                        <td>
                          {addedPrinters.indexOf(printerLocalId) !== -1
                            ? <span className="badge badge-info">настроен</span>
                            : (offices.length
                              ? (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={e => onConnect(clientId, printerLocalId)}
                                >
                                  подключить
                                </button>
                              )
                              : null
                            )
                          }
                        </td>
                      </tr>
                      {(
                        connectError
                        && connectError.clientId === clientId
                        && connectError.printerLocalId === printerLocalId
                      ) && (
                        <tr>
                          <td colSpan={3} className="bg-danger text-white">
                            {decodeException(connectError.error)}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            )
            : (
              <div className="card-body bg-warning text-center">
                Принтеры не обнаружены
              </div>
            )
          }
        </div>
      ))}

      {(!showKnown && showSessions.length < sessions.length) && (
        <div className="text-center mt-3">
          <button type="button" className="btn btn-primary" onClick={e => onShowKnown()}>
            Показать уже настроенные
          </button>
        </div>
      )}
    </div>
  );
}
