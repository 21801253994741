// @flow
import React from 'react';
import type { RouteAuth, RouteDef } from 'metaup/routing/routingUtils';
import HeaderWidgetView from './HeaderWidgetView';
import { bem } from '../../core/render/visualUtils';
import type { HeaderWidgetViewProps } from './HeaderWidgetView';
import NewsWidget from '../../library/NewsWidget';

export type LayoutProps = {
  topBar: ?HeaderWidgetViewProps,
  route: RouteDef,
  auth: RouteAuth,
  children: React$Node,
  width: number,
}

export function getScreenFromWidth(width) {
  return (width >= 1200 ? 'desktop' : 'mobile');
}

export default function MainLayoutView({
  topBar,
  route,
  auth,
  children,
  width,
}: LayoutProps) {
  const showNews = route.nav && route.nav.showNews;
  const isWide = showNews || (route.nav && route.nav.isWide);

  return (
    <div
      className={bem('layout', {
        'with-top-bar': topBar,
      }) + ' screen_' + getScreenFromWidth(width)}
    >
      {topBar && (
        <header className="layout__top-bar">
          <HeaderWidgetView {...topBar} />
        </header>
      )}
      <div className="layout__body">
        <div className={'layout__content-wrap ' + (isWide ? 'layout__content-wrap_wide' : '')}>
          {showNews && (
            <div className="layout__news">
              <NewsWidget />
            </div>
          )}
          <main className="layout__content" role="main">
            {children}
          </main>
        </div>
      </div>
      <footer className="layout__footer" />
    </div>
  );
}
