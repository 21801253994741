// @flow
import gql from 'graphql-tag';
import {
  apolloSubscribe,
  getSubscriptionAuthorization,
} from '../../core/data/apolloClient';
import type { SubscriptionListener } from '../../core/data/apolloClient';

export function onCutStatus(cutId, shape, listener: SubscriptionListener) {
  return apolloSubscribe(
    gql`
      subscription ($authorization: String!, $cutId: String!) {
        cutStatus(authorization: $authorization, cutId: $cutId) ${shape}
      }
    `,
    { cutId, authorization: getSubscriptionAuthorization() },
    listener
  );
}
