// @flow
import { AnyAction } from 'redux';
import { listFranchisees } from '../model/Franchisee.client';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Franchisee } from '../model/Franchisee.model';

type FranchiseeState = {
  all: Array<Franchisee>,
}

const initialState: FranchiseeState = {
  all: null,
};

const ACTION_SET_ALL = 'org/Franchisee/ACTION_SET_ALL';

function setAll(all) {
  return {
    type: ACTION_SET_ALL,
    all,
  };
}

export function franchiseeReducer(
  state: FranchiseeState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_ALL:
      return {
        ...state,
        all: action.all,
      };

    default:
      return state;
  }
}

export function getAllFranchisees(reduxState) {
  return reduxState.org.franchisee.all;
}

export async function loadAllFranchisees() {
  try {
    const franchisees = await listFranchisees({ isDeleted: false }, '{ id title }');

    return setAll(franchisees);
  } catch (err) {
    return setAll(new ErrorCapsule(err, () => [
      setAll(initialState.all),
      loadAllFranchisees(),
    ]));
  }
}
