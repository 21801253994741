// @flow
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { analyseLoaded, renderImageMeta } from '../../core/render/visualUtils';
import type { Variant } from '../model/Variant.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Printer } from '../../print/model/Printer.model';
import CutStatusWidget from '../../print/CutStatusWidget';
import type { Cut } from '../../print/model/Cut.model';

export type VariantPageViewProps = {
  variant: null | Variant | ErrorCapsule,
  printers: null | Array<Printer> | ErrorCapsule,
  testCut: null | Cut | ErrorCapsule,
  onTestCut: (variantId: string, printerId: string) => void,
}

export default function VariantPageView({
  variant,
  printers,
  testCut,
  onTestCut,
}: VariantPageViewProps) {
  const { t } = useTranslation();

  const [stateSelectedPrinterId, setSelectedPrinterId] = useState(null);
  const selectedPrinterId = stateSelectedPrinterId
    || ((Array.isArray(printers) && printers[0]) ? printers[0].id : null);

  const loaded = analyseLoaded(variant, printers);
  if (!loaded) return <Loader />;
  if (loaded instanceof ErrorCapsule) return <LoadFailed error={loaded} />;

  const {
    id,
    device,
    name,
    cut,
    preview,
    source,
    guide,
    notes,
    isEnabled,
  } = variant;

  return (
    <div className="variant">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/library/brands/">Бренды</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/library/devices/${device.brand.id}/`}>{device.brand.name}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/library/variants/${device.id}/`}>{device.name}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">{name}</li>
        </ol>
      </nav>

      <div className="d-flex justify-content-between align-items-center">
        <div className="d-inline-flex align-items-center">
          <h1>{name}</h1>
          {isEnabled
            ? <div className="badge badge-success ml-2">Включен</div>
            : <div className="badge badge-danger ml-2">Выключен</div>
          }
        </div>
        <Link to={`/library/variant-edit/${id}/`} className="btn btn-primary ml-3">изменить</Link>
      </div>

      <div className="d-flex mb-4">
        {preview && (
          <div className="pl-2 pr-4">{renderImageMeta(preview.fit, 'Preview')}</div>
        )}
        <div>
          <p>
            {cut
              ? (
                <a href={cut.downloadUrl} className="btn btn-outline-primary btn-sm mr-3">
                  <span className="oi oi-data-transfer-download align-middle mr-1" />
                  Файл в формате плоттера
                </a>
              )
              : <span className="badge badge-warning mr-3">Файл в формате плоттера не загружен</span>
            }

            {source
              ? (
                <a href={source.downloadUrl} className="btn btn-outline-primary btn-sm mr-3">
                  <span className="oi oi-data-transfer-download align-middle mr-1" />
                  Исходник
                </a>
              )
              : <span className="badge badge-warning mr-3">Исходник не загружен</span>
            }
          </p>
          {guide && (
            <>
              <small className="text-muted">Указания резчику</small>
              <p className="variant__guide">{guide}</p>
            </>
          )}
          {notes && (
            <>
              <small className="text-muted">Заметки себе</small>
              <p className="variant__notes">{notes}</p>
            </>
          )}
        </div>
      </div>
      <div className="card bg-light">
        <form className="card-body d-flex justify-content-center align-items-center">
          Тестовый рез на
          <select
            className="form-control ml-3 w-auto"
            value={selectedPrinterId || ''}
            onChange={e => setSelectedPrinterId(e.target.value)}
          >
            {printers.map(({ id: printerId, name: printerName }) => (
              <option key={printerId} value={printerId}>{printerName}</option>
            ))}
          </select>
          <button
            type="button"
            className="btn btn-primary ml-3 mr-3"
            onClick={e => onTestCut(id, selectedPrinterId)}
          >
            Запустить
          </button>
          <div style={{ width: '120px' }}>
            {testCut && (
              testCut instanceof ErrorCapsule
                ? <LoadFailed error={testCut} />
                : <CutStatusWidget id={testCut.id} />
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
