// @flow
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _isPlainObject from 'lodash/isPlainObject';

import Form from '../core/forms/Form';
import FormGlobalValidation from '../core/forms/FormGlobalValidation';
import Field, { getBootstrapValidationClassName } from '../core/forms/Field';
import type { FormProps } from '../core/forms/Form';
import type { FieldProps } from '../core/forms/Field';

/* eslint-disable react/no-array-index-key */

export function DefaultForm(props: FormProps) {
  return (
    <Form {...props} />
  );
}

export function DefaultFormGlobalValidation() {
  return (
    <FormGlobalValidation
      render={({ error, warning }) => (
        <Fragment>
          {error && <div className="alert alert-danger" role="alert">{error}</div>}
          {warning && <div className="alert alert-light text-warning" role="alert">{warning}</div>}
        </Fragment>
      )}
    />
  );
}

export function DefaultFormFieldWrapper(props: FieldWrapperProps) {
  const { renderControl } = props;
  return (
    <Field
      {...props}
      component={
        (params) => {
          const {
            input: { name },
            label,
            hint,
            meta: {
              form,
              touched,
              error,
              warning,
            },
          } = params;
          const id = form + '-' + name;
          const hintId = hint ? id + '-help' : null;
          return (
            <div className="form-group">
              {label && <label htmlFor={id}>{label}</label>}
              {renderControl({ ...params, id, hintId })}
              {
                touched && (
                  (error && <div className="invalid-feedback d-block">{error}</div>)
                  || (warning && <div className="form-text text-warning small">{warning}</div>)
                )
              }
              {hint && <div id={hintId} className="form-text text-muted small">{hint}</div>}
            </div>
          );
        }
      }
    />
  );
}

export type TextFieldProps = FieldProps & {
  type?: string,
}

export function DefaultFormText(props: TextFieldProps) {
  return (
    <DefaultFormFieldWrapper
      {...props}
      renderControl={
        ({
          input,
          id,
          hintId,
          meta: {
            touched,
            error,
            warning,
          },
        }) => (
          <input
            {...input}
            autoComplete={props.autoComplete}
            autoFocus={props.autoFocus}
            type={props.type || 'text'}
            className={classNames('form-control', getBootstrapValidationClassName(touched, error, warning))}
            id={id}
            aria-describedby={hintId}
          />
        )
      }
    />
  );
}

export function DefaultFormTextArea(props: FieldProps) {
  return (
    <DefaultFormFieldWrapper
      {...props}
      renderControl={
        ({
          input,
          id,
          hintId,
          meta: {
            touched,
            error,
            warning,
          },
        }) => (
          <textarea
            {...input}
            autoFocus={props.autoFocus}
            className={classNames('form-control', getBootstrapValidationClassName(touched, error, warning))}
            id={id}
            aria-describedby={hintId}
          />
        )
      }
    />
  );
}

/** @see https://github.com/erikras/redux-form/issues/3686#issuecomment-353116360 */
function handleFileChange(handler, transformer) {
  return ({ target: { files } }) => handler(transformer(files));
}

function transformSingleFile(files) {
  return files.length > 0 ? files[0] : null;
}

function transformMultipleFiles(files) {
  return files[0];
}

function fileNameOrCta(value, choseFileText) {
  if (!value || _isPlainObject(value)) return choseFileText;
  if (Array.isArray(value)) return value.map(file => file.name).join(', ');
  return value.name;
}

type FileProps = FieldProps & {|
  accept?: string,
  multiple?: boolean,
|}

export function DefaultFormFile({ accept, multiple, ...props }: FileProps) {
  const { t } = useTranslation(); // Not the best example of hooks usage

  const transformer = multiple ? transformMultipleFiles : transformSingleFile;
  return (
    <DefaultFormFieldWrapper
      type="file"
      {...props}
      renderControl={
        ({
          input: { onChange, onBlur, value, ...input },
          type,
          id,
          hintId,
          meta: {
            touched,
            error,
            warning,
          },
        }) => (
          <div className="custom-file">
            <input
              onChange={handleFileChange(onChange, transformer)}
              onBlur={handleFileChange(onBlur, transformer)}
              {...input}
              accept={accept}
              multiple={multiple}
              autoFocus={props.autoFocus}
              type={type}
              className={classNames('custom-file-input', getBootstrapValidationClassName(touched, error, warning))}
              id={id}
              aria-describedby={hintId}
            />
            <label // eslint-disable-line jsx-a11y/label-has-associated-control
              htmlFor={id}
              className={classNames('custom-file-label', getBootstrapValidationClassName(touched, error, warning))}
            >
              {fileNameOrCta(value, t('Choose file'))}
            </label>
          </div>
        )
      }
    />
  );
}

export function DefaultFormCheckBox(props: FieldProps) {
  return (
    <Field
      type="checkbox"
      {...props}
      component={
        (params) => {
          const {
            input,
            label,
            type,
            hint,
            meta: {
              form,
              touched,
              error,
              warning,
            },
          } = params;
          const id = form + '-' + input.name;
          const hintId = hint ? id + '-help' : null;
          return (
            <div className="form-group form-check">
              <input
                {...input}
                type={type}
                autoFocus={props.autoFocus}
                className={classNames('form-check-input', getBootstrapValidationClassName(touched, error, warning))}
                id={id}
                aria-describedby={hintId}
              />
              <label className="form-check-label" htmlFor={id}>
                {label}
              </label>
              {
                touched && (
                  (error && <div className="invalid-feedback d-block">{error}</div>)
                  || (warning && <div className="form-text text-warning small">{warning}</div>)
                )
              }
              {hint && <div id={hintId} className="form-text text-muted small">{hint}</div>}
            </div>
          );
        }
      }
    />
  );
}

export type DropDownFieldProps = FieldProps & {
  options: Array<[string, string]>,
}

export function DefaultFormDropDown(props: DropDownFieldProps) {
  return (
    <DefaultFormFieldWrapper
      {...props}
      renderControl={
        ({
          input,
          id,
          hintId,
          meta: {
            touched,
            error,
            warning,
          },
        }) => (
          <select
            {...input}
            autoFocus={props.autoFocus}
            className={classNames('form-control', getBootstrapValidationClassName(touched, error, warning))}
            id={id}
            aria-describedby={hintId}
          >
            {props.options.map(([value, title], index) => (
              <option key={index} value={value}>{title}</option>
            ))}
          </select>
        )
      }
    />
  );
}

type SubmitProps = {
  label: string,
}

export function DefaultFormSubmit({ label }: SubmitProps) {
  return (
    <div className="text-center">
      <button type="submit" className="btn btn-primary">{label}</button>
    </div>
  );
}
