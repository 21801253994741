// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { loginsHistoryReducer } from './LoginsHistoryPage';
import { salesReducer } from './SalesPage';
import { franchiseeSalesReducer } from './FranchiseeSalesPage';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'stats';

export const reducer = safeCombineReducers({
  loginsHistory: loginsHistoryReducer,
  sales: salesReducer,
  franchiseeSales: franchiseeSalesReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
