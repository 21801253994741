// @flow
import React, { Component } from 'react';
import _find from 'lodash/find';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import {
  getDevice,
  createDevice,
  updateDevice,
  filterDeviceInput,
} from './model/Device.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import DeviceEditPageView from './views/DeviceEditPageView';
import type { DeviceEditPageViewProps } from './views/DeviceEditPageView';
import type { Device } from './model/Device.model';
import { getBrand } from './model/Brand.client';
import type { DeviceType } from './model/DeviceType.model';
import { listDeviceTypes } from './model/DeviceType.client';

type DeviceEditState = {
  device: null | Device | ErrorCapsule,
  deviceTypes: null | Array<DeviceType> | ErrorCapsule,
}

const brandShape = `{
  id
  name
}`;

const deviceShape = `{
  id
  brandId
  brand ${brandShape}
  deviceTypeId
  name
  preview {
    id
    fit(width: 100, height: 100) { width height url }
  }
}`;

const initialState: DeviceEditState = {
  device: null,
  deviceTypes: null,
};

const ACTION_SET_DEVICE = 'library/deviceEdit/ACTION_SET_DEVICE';
const ACTION_SET_DEVICE_TYPES = 'library/deviceEdit/ACTION_SET_DEVICE_TYPES';

function setDevice(device: null | Device | ErrorCapsule) {
  return {
    type: ACTION_SET_DEVICE,
    device,
  };
}

function setDeviceTypes(deviceTypes) {
  return {
    type: ACTION_SET_DEVICE_TYPES,
    deviceTypes,
  };
}

async function loadDevice(id: string) {
  try {
    const device = await getDevice(id, deviceShape);

    return setDevice(device);
  } catch (err) {
    return setDevice(new ErrorCapsule(err, () => [
      setDevice(initialState.device),
      loadDevice(id),
    ]));
  }
}

async function loadDeviceTypes() {
  try {
    const deviceTypes = await listDeviceTypes(null, '{ id name }');

    return setDeviceTypes(deviceTypes);
  } catch (err) {
    return setDeviceTypes(new ErrorCapsule(err, () => [
      setDeviceTypes(initialState.deviceTypes),
      loadDeviceTypes(),
    ]));
  }
}

async function loadEmptyDevice(brandId: string) {
  try {
    const brand = await getBrand(brandId, brandShape);

    return setDevice({ brandId, brand });
  } catch (err) {
    return setDevice(new ErrorCapsule(err, () => [
      setDevice(initialState.device),
      loadEmptyDevice(brandId),
    ]));
  }
}

export async function saveDevice(values) {
  const device = !values.id
    ? await createDevice(filterDeviceInput(values), deviceShape)
    : await updateDevice(filterDeviceInput(values), deviceShape);

  return [
    setDevice(device),
    redirect(`/library/devices/${device.brandId}/`),
  ];
}

function makeSelectedTypeConsistent(device, deviceTypes) {
  if (!device || !deviceTypes) {
    return device;
  }

  if (_find(deviceTypes, ({ id }) => id === device.deviceTypeId)) {
    return device;
  }

  return {
    ...device,
    deviceTypeId: deviceTypes[0].id,
  };
}

export function deviceEditReducer(
  state: DeviceEditState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_DEVICE:
      return {
        ...state,
        device: makeSelectedTypeConsistent(action.device, state.deviceTypes),
      };

    case ACTION_SET_DEVICE_TYPES:
      return {
        ...state,
        deviceTypes: action.deviceTypes,
        device: makeSelectedTypeConsistent(state.device, action.deviceTypes),
      };

    default:
      return state;
  }
}

const DeviceEditPage = connect(
  ({ library }): DeviceEditPageViewProps => ({
    device: library.deviceEdit.device,
    deviceTypes: library.deviceEdit.deviceTypes,
  }),
)(class extends Component<DeviceEditPageViewProps> {
  constructor(props) {
    const { id, brandId, dispatch, deviceTypes } = props;
    super(props);

    dispatch([
      ...(!deviceTypes ? [loadDeviceTypes()] : []),
      ...(id ? [
        setDevice(null),
        loadDevice(id),
      ] : [
        loadEmptyDevice(brandId),
      ]),
    ]);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setDevice(null));
  }

  render() {
    const { dispatch } = this.props;
    return (
      <DeviceEditPageView
        {...this.props}
        onSubmit={(values: Device) => dispatch(saveDevice(values))}
      />
    );
  }
});

export function deviceEditPageRoutes(): Routes {
  return [
    {
      title: 'Добавление модели',
      path: '/device-add/:brandId/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <DeviceEditPage
          {...sanitizeRouteParams(params, {
            brandId: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
    {
      title: 'Редактирование модели',
      path: '/device-edit/:id/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <DeviceEditPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
