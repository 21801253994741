// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Printer } from '../model/Printer.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { useDialog } from '../../elements/Dialog';

export type PrintersListPageViewProps = {
  printers: null | Array<Printer> | ErrorCapsule,
  onDeletePrinter: (printerId: string) => void,
}

export default function PrintersListPageView({
  printers,
  onDeletePrinter,
}: PrintersListPageViewProps) {
  const { t } = useTranslation();
  const { confirm } = useDialog();

  if (!printers) return <Loader />;
  if (printers instanceof ErrorCapsule) return <LoadFailed error={printers} />;

  return (
    <div className="printers-list">
      <h1>Управление плоттерами</h1>

      <div className="mb-1 text-right">
        <Link to="/print/add-printer/" className="btn btn-primary btn-sm">подключить плоттер</Link>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Название</th>
            <th>Модель</th>
            <th>Офис</th>
            <th>Франчайзи</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {printers.map(({
            id,
            name,
            model,
            office,
          }) => (
            <tr key={id} className="printers-list__printer">
              <td><Link to={`/print/printers/${id}/`}>{name}</Link></td>
              <td>{model}</td>
              <td>{office.title}</td>
              <td>{office.franchisee ? office.franchisee.title : 'Свой офис'}</td>
              <td className="text-right text-nowrap">
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={e => confirm({
                    renderBody: () => `Удаляем ${name}?`,
                    handler: () => onDeletePrinter(id),
                  })}
                >
                  удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
