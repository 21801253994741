// @flow
import React, { Component } from 'react';
import type { Props as ReduxFormFieldProps } from 'redux-form/es/FieldProps.types.js.flow';
import { Field as ReduxFormField } from 'redux-form';
import _has from 'lodash/has';
import _startCase from 'lodash/startCase';
import type { FormContext } from './Form';
import { FormContextType } from './Form';
import UnexpectedCaseException from '../exceptions/UnexpectedCaseException';

export type FieldProps = {
  attribute?: string, // Model attribute
  name?: string, // Default to attribute
  autoFocus?: boolean,
  autoComplete?: boolean,

  // Meta-model overrides
  rules?: RulesList,
  label?: string,
  hint?: string,
};

export type FieldWrapperProps = FieldProps & {
  renderControl: Object => React$Element<any>,
};

export function parseFormFieldProps(props: FieldProps, context: FormContext) {
  const { attribute } = props;
  const { metaModel } = context;
  let { rules, label, hint } = props;

  // Dive into nested, if required
  if (attribute) {
    const [root, rest] = attribute.split('.', 2);
    if (rest) {
      const nestedMetaModel = metaModel.nested[root];
      if (!nestedMetaModel) {
        throw new UnexpectedCaseException();
      }
      return parseFormFieldProps(
        { attribute: rest, rules, label, hint },
        { metaModel: nestedMetaModel }
      );
    }
  }

  // Extract defaults from meta model
  if (rules === undefined) {
    // Rules are required in subsequent implementation
    rules = (metaModel && attribute) ? metaModel.rules[attribute] : [];
  }
  if (metaModel && attribute) {
    if (label === undefined) {
      if (metaModel.labels && _has(metaModel.labels, attribute)) {
        label = metaModel.labels[attribute];
      } else {
        // Attribute is a good candidate for a name itself
        label = _startCase(attribute);
      }
    }
    if (hint === undefined && metaModel.hints) {
      hint = metaModel.hints[attribute];
    }
  }

  return { rules, label, hint };
}

export function getBootstrapValidationClassName(touched, error, warning) {
  if (!touched) {
    return null;
  }
  if (error) {
    return 'is-invalid';
  }
  // TODO: if (warning) {}
  return 'is-valid';
}

export default class Field extends Component<ReduxFormFieldProps & FieldProps, FormContext> {
  static contextType = FormContextType;

  render() {
    const { attribute, name, validate, warn } = this.props;
    const { rules, label, hint } = parseFormFieldProps(this.props, this.context);
    return (
      // Flow generates nonsense here
      // that's because of redux-form typings, incompatible with its effective implementation
      // $FlowDisable
      <ReduxFormField
        {...this.props}
        name={name || attribute}
        validate={validate || extractValidate(rules)}
        warn={warn || extractWarn(rules)}
        label={label}
        hint={hint}
      />
    );
  }
}

function extractValidate(fieldRules: FieldRules): RulesList {
  if (Array.isArray(fieldRules)) {
    return fieldRules;
  }
  if (!fieldRules) {
    throw new UnexpectedCaseException();
  }

  let result = [];

  if (fieldRules.must) {
    result = fieldRules.must;
  }
  if (fieldRules.clientOnly) {
    result = [...result, ...fieldRules.clientOnly];
  }

  return result;
}

function extractWarn(fieldRules: FieldRules): ?RulesList {
  return Array.isArray(fieldRules) ? undefined : fieldRules.warn;
}
