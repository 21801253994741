// @flow
import { required } from 'redux-form-validators';
import { string, file, image, boolean } from '../../core/data/rules';
import type { Device } from './Device.model';
import type { MetaModel } from '../../core/data/rules';

export type Variant = {|
  id: string,
  deviceId: string,
  device: Device,
  name: string,
  cut: ?string,
  preview: ?ImageField,
  source: ?string,
  guide: ?string,
  notes: ?string,
  isEnabled: boolean,
|}


export const variantMetaModel: MetaModel = {
  name: 'Variant',
  rules: {
    deviceId: [string(), required()],
    name: [string(), required()],
    cut: [file()],
    preview: [image()],
    source: [file()],
    guide: [string()],
    notes: [string()],
    isEnabled: [boolean(), required()],
  },
  labels: {
    name: 'Название',
    cut: 'Файл в формате плоттера',
    preview: 'Изображение',
    source: 'Исходник',
    guide: 'Указания для резчика',
    notes: 'Заметки для себя',
    isEnabled: 'Разрешить использование',
  },
};
