// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Franchisee } from '../model/Franchisee.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { useDialog } from '../../elements/Dialog';

export type FranchiseesListPageViewProps = {
  franchisees: null | Array<Franchisee> | ErrorCapsule,
  onDeleteFranchisee: (id: string) => void,
}

export default function FranchiseesListPageView({
  franchisees,
  onDeleteFranchisee,
}: FranchiseesListPageViewProps) {
  const { t } = useTranslation();
  const { confirm } = useDialog();

  if (!franchisees) return <Loader />;
  if (franchisees instanceof ErrorCapsule) return <LoadFailed error={franchisees} />;

  return (
    <div className="franchisees-list">
      <h1>Франчайзи</h1>

      <div className="mb-1 text-right">
        <Link to="/org/franchisee-add/" className="btn btn-primary btn-sm">добавить компанию</Link>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Название</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {franchisees.map(({
            id,
            title,
            notes,
          }) => (
            <tr key={id} className="franchisees-list__franchisee">
              <td>{title}</td>
              <td className="text-right text-nowrap">
                <Link to={`/org/franchisee-edit/${id}/`} className="btn btn-primary btn-sm">
                  изменить
                </Link>
                &nbsp;
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={e => confirm({
                    renderBody: () => `Удаляем ${title}?`,
                    handler: () => onDeleteFranchisee(id),
                  })}
                >
                  удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
