// @flow
import { required } from 'redux-form-validators';
import { string, image } from '../../core/data/rules';
import type { Brand } from './Brand.model';
import type { DeviceType } from './DeviceType.model';
import type { Variant } from './Variant.model';
import type { MetaModel } from '../../core/data/rules';

export type Device = {|
  id: string,
  brandId: string,
  brand: Brand,
  deviceTypeId: string,
  deviceType: DeviceType,
  name: string,
  preview: ?ImageField,
  variants: [Variant],
|}


export const deviceMetaModel: MetaModel = {
  name: 'Device',
  rules: {
    brandId: [string(), required()],
    deviceTypeId: [string(), required()],
    name: [string(), required()],
    preview: [image()],
  },
  labels: {
    name: 'Название',
    deviceTypeId: 'Тип устройства',
    preview: 'Картинка',
  },
};
