// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
  DefaultFormFile,
  DefaultFormCheckBox, DefaultFormTextArea,
} from '../../elements/DefaultForm';

import type { Variant } from '../model/Variant.model';
import { variantMetaModel } from '../model/Variant.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { announceFileIsUploaded } from '../../core/render/visualUtils';

export type VariantEditPageViewProps = {
  variant: null | Variant | ErrorCapsule,
  onSubmit: (values: { [string]: any }) => void,
}

export default function VariantEditPageView({
  variant,
  onSubmit,
}: VariantEditPageViewProps) {
  const { t } = useTranslation();

  if (!variant) return <Loader />;
  if (variant instanceof ErrorCapsule) return <LoadFailed error={variant} />;

  const isCreating = variant.id == null;
  const pageTitle = isCreating
    ? 'Добавление варианта'
    : `Редактирование ${variant.name}`;

  return (
    <div className="variant-edit">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/library/brands/">Бренды</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/library/devices/${variant.device.brand.id}/`}>{variant.device.brand.name}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/library/variants/${variant.device.id}/`}>{variant.device.name}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">{pageTitle}</li>
        </ol>
      </nav>
      <h1>{pageTitle}</h1>

      <DefaultForm
        form="variantEdit"
        metaModel={variantMetaModel}
        initialValues={variant}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormText attribute="name" autoFocus />
        <DefaultFormFile attribute="cut" hint={announceFileIsUploaded(variant.cut)} />
        <DefaultFormFile attribute="preview" hint={announceFileIsUploaded(variant.preview)} />
        <DefaultFormFile attribute="source" hint={announceFileIsUploaded(variant.source)} />
        <DefaultFormTextArea attribute="guide" />
        <DefaultFormTextArea attribute="notes" />
        <DefaultFormCheckBox attribute="isEnabled" />
        <DefaultFormSubmit label={isCreating ? 'Добавить' : 'Изменить'} />
      </DefaultForm>
    </div>
  );
}
