// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Office } from '../model/Office.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import { useDialog } from '../../elements/Dialog';

export type OfficesListPageViewProps = {
  offices: null | Array<Office> | ErrorCapsule,
  onDeleteOffice: (id: string) => void,
}

export default function OfficesListPageView({
  offices,
  onDeleteOffice,
}: OfficesListPageViewProps) {
  const { t } = useTranslation();
  const { confirm } = useDialog();

  if (!offices) return <Loader />;
  if (offices instanceof ErrorCapsule) return <LoadFailed error={offices} />;

  return (
    <div className="offices-list">
      <h1>Офисы</h1>

      <div className="mb-1 text-right">
        <Link to="/org/office-add/" className="btn btn-primary btn-sm">добавить офис</Link>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Название</th>
            <th>Франчайзи</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {offices.map(({
            id,
            title,
            franchisee,
            notes,
          }) => (
            <tr key={id} className="franchisees-list__franchisee">
              <td>{title}</td>
              <td>{franchisee ? franchisee.title : 'Свой офис'}</td>
              <td className="text-right text-nowrap">
                <Link to={`/org/office-edit/${id}/`} className="btn btn-primary btn-sm">
                  изменить
                </Link>
                &nbsp;
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={e => confirm({
                    renderBody: () => `Удаляем ${title}?`,
                    handler: () => onDeleteOffice(id),
                  })}
                >
                  удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
