// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { listPrintClientSessions, onPrintClientSessions } from './model/PrintClientSession.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import AddPrinterPageView from './views/AddPrinterPageView';
import type { PrintClientSession } from './model/PrintClientSession.model';
import { redirect } from '../core/data/router.redux';
import { createPrinter } from './model/Printer.client';
import RealtimeCollection from '../core/data/RealtimeCollection';
import { getAllOffices, loadAllOffices } from '../org/redux/Office.redux';

type AddPrinterState = {
  sessions: null | Array<PrintClientSession> | ErrorCapsule,
  selectedOfficeId: ?string,
  connectError: ?{
    clientId: string,
    printerLocalId: string,
    error: Error,
  },
  showKnown: boolean,
}

const sessionShape = `{
  id
  startedAt
  lostAt
  ip
  logExtra
  clientId
  clientSessionId
  availablePrinters
  addedPrinters
}`;

const initialState: AddPrinterState = {
  sessions: null,
  selectedOfficeId: null,
  connectError: null,
  showKnown: false,
};

const ACTION_SET_PRINT_CLIENT_SESSIONS = 'print/addPrinter/ACTION_SET_PRINT_CLIENT_SESSIONS';
const ACTION_SET_CONNECT_ERROR = 'print/addPrinter/ACTION_SET_CONNECT_ERROR';
const ACTION_SET_SHOW_KNOWN = 'print/addPrinter/ACTION_SET_SHOW_KNOWN';
const ACTION_SELECT_OFFICE = 'print/addPrinter/ACTION_SELECT_OFFICE';

function setSessions(sessions: null | Array<PrintClientSession> | ErrorCapsule) {
  return {
    type: ACTION_SET_PRINT_CLIENT_SESSIONS,
    sessions,
  };
}

function setConnectError(clientId, printerLocalId, error) {
  return {
    type: ACTION_SET_CONNECT_ERROR,
    connectError: { clientId, printerLocalId, error },
  };
}

function setShowKnown(value = true) {
  return {
    type: ACTION_SET_SHOW_KNOWN,
    value,
  };
}

function selectOffice(id) {
  return {
    type: ACTION_SELECT_OFFICE,
    id,
  };
}

async function connectPrinter(clientId, printerLocalId, officeId) {
  try {
    const printer = await createPrinter({ clientId, printerLocalId, officeId }, '{ id }');

    return redirect(`/print/printers/${printer.id}/`);
  } catch (err) {
    return setConnectError(clientId, printerLocalId, err);
  }
}

export function addPrinterReducer(
  state: AddPrinterState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_PRINT_CLIENT_SESSIONS:
      return {
        ...state,
        sessions: action.sessions,
      };

    case ACTION_SET_CONNECT_ERROR:
      return {
        ...state,
        connectError: action.connectError,
      };

    case ACTION_SET_SHOW_KNOWN:
      return {
        ...state,
        showKnown: action.value,
      };

    case ACTION_SELECT_OFFICE:
      return {
        ...state,
        selectedOfficeId: action.id,
      };

    default:
      return state;
  }
}

type Props = {};

function AddPrinterPage({}: Props) {
  const dispatch = useDispatch();
  const {
    sessions,
    selectedOfficeId,
    connectError,
    showKnown,
  } = useSelector(({ print }) => print.addPrinter);
  const offices = useSelector(getAllOffices);

  // Reset show known on load
  useEffect(() => {
    dispatch([
      setShowKnown(false),
      ...(!offices ? [loadAllOffices()] : []),
    ]);

    const collection = new RealtimeCollection({
      list: async () => listPrintClientSessions({ lostAt: null }, sessionShape),
      subscribe: listener => onPrintClientSessions(sessionShape, listener),
      listener: freshSessions => { dispatch(setSessions(freshSessions)) },
    });
    collection.safeOpen(capsule => dispatch(setSessions(capsule)));
    return () => collection.close();
  }, []);

  const effectiveSelectedOfficeId = (Array.isArray(offices) && offices.length > 0)
    ? (selectedOfficeId || offices[0].id) : null;

  // Render
  return (
    <AddPrinterPageView
      sessions={sessions}
      offices={offices}
      selectedOfficeId={effectiveSelectedOfficeId}
      onSelectOffice={officeId => dispatch(selectOffice(officeId))}
      connectError={connectError}
      showKnown={showKnown}
      onConnect={(clientId, printerLocalId) => dispatch(
        connectPrinter(clientId, printerLocalId, effectiveSelectedOfficeId)
      )}
      onShowKnown={() => dispatch(setShowKnown(true))}
    />
  );
}

export function addPrinterPageRoutes(): Routes {
  return [
    {
      title: 'Добавление принтера',
      path: '/add-printer/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <AddPrinterPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
