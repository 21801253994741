// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _map from 'lodash/map';
import type { AuthAudit } from '../../auth/model/AuthAudit.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { User } from '../../users/model/User.model';
import { cleanUpIpAddress } from '../../core/render/visualUtils';

export type LoginsHistoryPageViewProps = {
  isAdmin: boolean,
  authAudits: null | Array<AuthAudit> | ErrorCapsule,
}

function describeOffice(user: User, forAdmin) {
  let result = '';
  if (user.office) {
    result = user.office.title;
  }
  if (forAdmin && user.franchisee) {
    if (result) result += ', ';
    result += user.franchisee.title;
  }
  return result;
}

function headersToString(headers) {
  return _map(headers, (value, key) => key + ': ' + value).join('\n');
}

export default function LoginsHistoryPageView({
  isAdmin,
  authAudits,
}: LoginsHistoryPageViewProps) {
  const { t } = useTranslation();

  if (!authAudits) return <Loader />;
  if (authAudits instanceof ErrorCapsule) return <LoadFailed error={authAudits} />;

  return (
    <div className="logins-history">
      <h1>История входов</h1>
      <table className="table table-sm table-striped logins-history__table">
        <thead>
          <tr>
            <th>Вход</th>
            <th>Окончание сессии</th>
            <th>E-mail</th>
            <th>Пользователь</th>
            <th>Офис {isAdmin ? '/ Франчайзи' : null}</th>
            <th>IP</th>
            <th>Прочее</th>
          </tr>
        </thead>
        <tbody>
          {authAudits.map(({
            id,
            start,
            end,
            email,
            error,
            user,
            ip,
            userAgent,
            headers,
          }) => (
            <tr key={id} className="logins-history__item">
              <td className="text-nowrap">
                {moment(start + 'Z').format('DD.MM.YYYY HH:mm:ss')}
                {error && <div><span className="badge badge-warning">{error}</span></div>}
              </td>
              <td className="text-nowrap">{end ? moment(end + 'Z').format('DD.MM.YYYY HH:mm:ss') : 'нет'}</td>
              <td>{email}</td>
              {user
                ? (
                  <>
                    <td><Link to={`/users/edit/${user.id}/`}>{user.email}</Link></td>
                    <td>{describeOffice(user, isAdmin)}</td>
                  </>
                )
                : <td colSpan={2}>Вход отклонён</td>
              }
              <td>{cleanUpIpAddress(ip)}</td>
              <td title={`${userAgent}\n\n${headersToString(headers)}`}>
                ...
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
