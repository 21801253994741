// @flow
import React from 'react';
import { Redirect } from 'react-router-dom';
import type { RouteAuth, Routes } from 'metaup/routing/routingUtils';
import type { User } from '../users/model/User.model';

export function homeRoutes(): Routes {
  return [
    {
      title: 'Home',
      path: '/',
      render: (params, { authState: { user } }: RouteAuth) => {
        if (!user) {
          return <Redirect to="/login/" />;
        }
        const { isFranchiseeManager, isAdmin }: User = user.user;
        if (isAdmin || isFranchiseeManager) {
          return <Redirect to="/stats/sales/" />;
        }
        return <Redirect to="/frontdesk/" />;
      },
    },
  ];
}
