// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
} from '../../elements/DefaultForm';
import { Franchisee, franchiseeMetaModel } from '../model/Franchisee.model';

import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';

export type FranchiseeEditPageViewProps = {
  franchisee: null | Franchisee | ErrorCapsule,
  onSubmit: (values: { [string]: any }) => void,
}

export default function FranchiseeEditPageView({
  franchisee,
  onSubmit,
}: FranchiseeEditPageViewProps) {
  const { t } = useTranslation();

  if (!franchisee) return <Loader />;
  if (franchisee instanceof ErrorCapsule) return <LoadFailed error={franchisee} />;
  const isCreating = franchisee.id == null;

  const pageTitle = isCreating
    ? 'Добавление франчайзи'
    : `Редактирование ${franchisee.title}`;

  return (
    <div className="franchisee-edit">
      <h1>{pageTitle}</h1>
      <DefaultForm
        form="franchiseeEdit"
        metaModel={franchiseeMetaModel}
        initialValues={franchisee}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormText attribute="title" autoFocus />
        <DefaultFormText attribute="notes" />
        <DefaultFormSubmit label={isCreating ? 'Добавить' : 'Изменить'} />
      </DefaultForm>
    </div>
  );
}
