// @flow
import { loginPageRoutes } from './LoginPage';
// ### GENERATED IMPORTS GO HERE ###

export function authRoutes() {
  return [
    loginPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ];
}
