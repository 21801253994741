// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import {
  getFranchisee,
  createFranchisee,
  updateFranchisee,
  filterFranchiseeInput,
} from './model/Franchisee.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import FranchiseeEditPageView from './views/FranchiseeEditPageView';
import type { FranchiseeEditPageViewProps } from './views/FranchiseeEditPageView';
import type { Franchisee } from './model/Franchisee.model';

type FranchiseeEditState = {
  franchisee: null | Franchisee | ErrorCapsule,
}

const franchiseeShape = `{
  id
  title
  notes
}`;

const initialState: FranchiseeEditState = {
  franchisee: null,
};

const ACTION_SET_FRANCHISEE = 'org/franchiseeEdit/ACTION_SET_FRANCHISEE';

function setFranchisee(franchisee: null | Franchisee | ErrorCapsule) {
  return {
    type: ACTION_SET_FRANCHISEE,
    franchisee,
  };
}

async function loadFranchisee(id: string) {
  try {
    const franchisee = await getFranchisee(id, franchiseeShape);

    return setFranchisee(franchisee);
  } catch (err) {
    return setFranchisee(new ErrorCapsule(err, () => [
      setFranchisee(initialState.franchisee),
      loadFranchisee(id),
    ]));
  }
}

export async function saveFranchisee(values) {
  const franchisee = !values.id
    ? await createFranchisee(filterFranchiseeInput(values), franchiseeShape)
    : await updateFranchisee(filterFranchiseeInput(values), franchiseeShape);

  return [
    setFranchisee(franchisee),
    redirect('/org/franchisees/'),
  ];
}

export function franchiseeEditReducer(
  state: FranchiseeEditState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_FRANCHISEE:
      return {
        ...state,
        franchisee: action.franchisee,
      };

    default:
      return state;
  }
}

const FranchiseeEditPage = connect(
  ({ org }): FranchiseeEditPageViewProps => ({
    franchisee: org.franchiseeEdit.franchisee,
  }),
)(class extends Component<FranchiseeEditPageViewProps> {
  constructor(props) {
    const { id, dispatch } = props;
    super(props);

    dispatch(id ? [
      setFranchisee(null),
      loadFranchisee(id),
    ] : setFranchisee({}));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setFranchisee(null));
  }

  render() {
    const { dispatch } = this.props;
    return (
      <FranchiseeEditPageView
        {...this.props}
        onSubmit={(values: Franchisee) => dispatch(saveFranchisee(values))}
      />
    );
  }
});

export function franchiseeEditPageRoutes(): Routes {
  return [
    {
      title: 'Добавление франчайзи',
      path: '/franchisee-add/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <FranchiseeEditPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
    {
      title: 'Редактирование франчайзи',
      path: '/franchisee-edit/:id/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <FranchiseeEditPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
