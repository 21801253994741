// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { Routes } from 'metaup/routing/routingUtils';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';

import LoginPageView from './views/LoginPageView';
import { login } from './authState';

const LoginPage = connect(
  null,
  {
    onSubmit: ({ email, password }) => login(email, password),
  }
)(LoginPageView);

export function loginPageRoutes(): Routes {
  return [
    {
      path: '/login',
      render: params => (
        <LoginPage
          {...sanitizeRouteParams(params, {
          }, {
          })}
        />
      ),
      design: require('./views/LoginPageView.design@mobile.png'), // eslint-disable-line global-require
      title: 'Вход',
    },
  ];
}
