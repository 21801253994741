// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { error404Reducer } from './Error404Page';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'statics';

export const reducer = safeCombineReducers({
  error404: error404Reducer,
  // ### GENERATED REDUCERS GO HERE ###
});
