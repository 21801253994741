// @flow
import _filter from 'lodash/filter';
import _values from 'lodash/values';
import _mapKeys from 'lodash/mapKeys';
import { SubmissionError } from 'redux-form';
import { split } from 'apollo-link';
import { ApolloClient, ApolloError } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import errorMessages from '../exceptions/errorMessages';
import devUtils from '../../dev/dev.frontend';
import { asyncErrorHandler } from '../backend/backendErrorHandler';

let params = {
  jwt: null,
  tz: detectTimeZone(),
  lang: 'ru',
  qa: devUtils.getQaServerParams
    ? devUtils.getQaServerParams()
    : JSON.parse(devUtils.getQaHeader()), // Legacy
};

let apollo: ApolloClient;
let wsLink: ?WebSocketLink = null;

function detectTimeZone() {
  let result = -(new Date()).getTimezoneOffset();
  try {
    result += ' ' + Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // Not supported. That's ok
  }
  return result;
}

function updateParams(delta = {}) {
  params = { ...params, ...delta };

  if (wsLink) {
    wsLink.subscriptionClient.close(true, true);
  }
  wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_SUBSCRIPTIONS_BACKEND,
    options: {
      reconnect: true,
      connectionParams: params,
      lazy: !params.jwt, // Lazy for guest, start tracking for user
    },
  });

  // Patch the library. @see https://github.com/apollographql/subscriptions-transport-ws/issues/377
  wsLink.subscriptionClient.maxConnectTimeGenerator.duration = (
    () => wsLink.subscriptionClient.maxConnectTimeGenerator.max
  );

  apollo = new ApolloClient({
    link: split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition'
          && definition.operation === 'subscription'
        );
      },
      wsLink,
      createUploadLink({
        uri: process.env.REACT_APP_BACKEND,
        headers: {
          ...(params.jwt ? { Authorization: 'JWT ' + params.jwt } : {}),
          ...(params.tz ? { 'X-TZ': params.tz } : {}),
          ...(params.lang ? { 'Accept-Language': params.lang } : {}),
          ...(params.qa ? { 'X-QA': JSON.stringify(params.qa) } : {}),
        },
      }),
    ),
    cache: new InMemoryCache(),
    // defaultOptions: {
    //   watchQuery: {
    //     fetchPolicy: 'no-cache',
    //     errorPolicy: 'all',
    //   },
    //   query: {
    //     fetchPolicy: 'no-cache',
    //     errorPolicy: 'all',
    //   },
    //   mutate: {
    //     fetchPolicy: 'no-cache',
    //     errorPolicy: 'all',
    //   },
    // },
  });
}
updateParams();

export function apolloAuth(jwt = null) {
  if (jwt !== params.jwt) {
    updateParams({ jwt });
  }
}

export function apolloSetLang(lang) {
  if (lang !== params.lang) {
    updateParams({ lang });
  }
}

export async function apolloQuery(query, variables) {
  const result = await apollo.query({ query, variables });
  return _values(result.data)[0];
}

export async function apolloMultiQuery(query, variables) {
  const result = await apollo.query({ query, variables });
  return result.data;
}

export async function apolloMutate(mutation, variables) {
  const e2eDone = devUtils.startServerActionRequest();
  try {
    const result = await apollo.mutate({ mutation, variables });
    asyncErrorHandler(apollo.clearStore()); // Clean caches
    return _values(result.data)[0];
  } finally {
    e2eDone();
  }
}

export type SubscriptionListener = any => void;

/**
 * @deprecated
 */
export function getSubscriptionAuthorization() {
  return params.jwt;
}

export function apolloSubscribe(query, variables, listener: SubscriptionListener): () => void {
  const subscription = apollo
    .subscribe({ query, variables })
    .subscribe({
      next: result => {
        listener(_values(result.data)[0]);
      },
      error(err) {
        console.error(err);
      },
    });

  return subscription.unsubscribe.bind(subscription);
}

export function getFormErrorsFromApolloException(err: Error): ?Object {

  // Not our case
  if (!(err instanceof ApolloError)) {
    return null;
  }

  // Catch connection issues
  if (err.networkError != null) {
    return { _error: errorMessages.connectionProblems };
  }

  // Catch user errors
  const userErrors = _filter(
    err.graphQLErrors,
    ({ extensions }) => extensions && extensions.code === 'BAD_USER_INPUT'
  );
  if (userErrors.length) {
    // Desired case, from validators
    if (
      userErrors[0].extensions
      && userErrors[0].extensions.exception
      && userErrors[0].extensions.exception.errors
    ) {
      return _mapKeys(
        userErrors[0].extensions.exception.errors,
        (_, key) => (key === '_' ? '_error' : key)
      );
    }

    // Not from validators
    return {
      _error: userErrors.map(({ message }) => message).join('\n'),
    };
  }

  // Handle the rest problems
  return { _error: errorMessages.serverProblems };
}

export async function apolloSubmitForm(mutation, variables) {
  const e2eDone = devUtils.startSubmittingForm();
  try {
    const response = await apollo.mutate({ mutation, variables });
    asyncErrorHandler(apollo.clearStore()); // Clean caches
    return _values(response.data)[0];
  } catch (err) {
    const formErrors = getFormErrorsFromApolloException(err);
    if (formErrors !== null) {
      throw new SubmissionError(formErrors);
    } else {
      throw err;
    }
  } finally {
    e2eDone();
  }
}
