// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Brand } from '../../library/model/Brand.model';

export type CutPageViewProps = {
  brands: Array<Brand>,
}

export default function CutPageBrandsView({
  brands,
}: CutPageViewProps) {
  const { t } = useTranslation();

  return (
    <div className="cut">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">Бренды</li>
        </ol>
      </nav>

      <h1>Выберите марку</h1>
      <div className="row">
        {brands.map(({
          id,
          name,
          logo,
        }) => (
          <div key={id} className="col-4 pb-4">
            <Link to={`/frontdesk/${id}/`} className="card flex-row">
              <div
                className="cut__tile-image"
                style={{ backgroundImage: logo ? `url(${logo.fit.url})` : null }}
              />
              <div className="card-body">
                <h5 className="card-title">{name}</h5>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
