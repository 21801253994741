// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';
import { loginsHistoryPageRoutes } from './LoginsHistoryPage';
import { salesPageRoutes } from './SalesPage';
import { franchiseeSalesPageRoutes } from './FranchiseeSalesPage';
// ### GENERATED IMPORTS GO HERE ###

export function statsRoutes() {
  return prefixRoutes('/stats/', [
    loginsHistoryPageRoutes(),
    salesPageRoutes(),
    franchiseeSalesPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
