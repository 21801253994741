// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import {
  getBrand,
  createBrand,
  updateBrand,
  filterBrandInput,
} from './model/Brand.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import BrandEditPageView from './views/BrandEditPageView';
import type { BrandEditPageViewProps } from './views/BrandEditPageView';
import type { Brand } from './model/Brand.model';

type BrandEditState = {
  brand: null | Brand | ErrorCapsule,
}

const brandShape = `{
  id
  name
  logo {
    id
    fit(width: 100, height: 100) { width height url }
  }
}`;

const initialState: BrandEditState = {
  brand: null,
};

const ACTION_SET_BRAND = 'library/brandEdit/ACTION_SET_BRAND';

function setBrand(brand: null | Brand | ErrorCapsule) {
  return {
    type: ACTION_SET_BRAND,
    brand,
  };
}

async function loadBrand(id: string) {
  try {
    const brand = await getBrand(id, brandShape);

    return setBrand(brand);
  } catch (err) {
    return setBrand(new ErrorCapsule(err, () => [
      setBrand(initialState.brand),
      loadBrand(id),
    ]));
  }
}

export async function saveBrand(values) {
  const brand = !values.id
    ? await createBrand(filterBrandInput(values), brandShape)
    : await updateBrand(filterBrandInput(values), brandShape);

  return [
    setBrand(brand),
    redirect('/library/brands/'),
  ];
}

export function brandEditReducer(
  state: BrandEditState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_BRAND:
      return {
        ...state,
        brand: action.brand,
      };

    default:
      return state;
  }
}

const BrandEditPage = connect(
  ({ library }): BrandEditPageViewProps => ({
    brand: library.brandEdit.brand,
  }),
)(class extends Component<BrandEditPageViewProps> {
  constructor(props) {
    const { id, dispatch } = props;
    super(props);

    dispatch(id ? loadBrand(id) : setBrand({}));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setBrand(null));
  }

  render() {
    const { dispatch } = this.props;
    return (
      <BrandEditPageView
        {...this.props}
        onSubmit={(values: Brand) => dispatch(saveBrand(values))}
      />
    );
  }
});

export function brandEditPageRoutes(): Routes {
  return [
    {
      title: 'Добавление модели',
      path: '/brand-add/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <BrandEditPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
    {
      title: 'Редактирование модели',
      path: '/brand-edit/:id/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <BrandEditPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
