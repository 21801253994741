// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { getVariant } from './model/Variant.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import VariantPageView from './views/VariantPageView';
import type { VariantPageViewProps } from './views/VariantPageView';
import type { Variant } from './model/Variant.model';
import type { Printer } from '../print/model/Printer.model';
import { listPrinters } from '../print/model/Printer.client';
import type { Cut } from '../print/model/Cut.model';
import { createCut } from '../print/model/Cut.client';

type VariantState = {
  variant: null | Variant | ErrorCapsule,
  printers: null | Array<Printer> | ErrorCapsule,
  testCut: null | Cut | ErrorCapsule,
}

const variantShape = `{
  id
  deviceId
  device {
    id
    brand {
      id
      name
    }
    name
  }
  name
  cut { id downloadUrl }
  preview {
    id
    fit(width: 100, height: 100) { width height url }
  }
  source { id downloadUrl }
  guide
  notes
  isEnabled
}`;

const initialState: VariantState = {
  variant: null,
  printers: [],
  testCut: null,
};

const ACTION_SET_VARIANT = 'library/variant/ACTION_SET_VARIANT';
const ACTION_SET_PRINTERS = 'library/variant/ACTION_SET_PRINTERS';
const ACTION_SET_TEST_CUT = 'library/variant/ACTION_SET_TEST_CUT';

function setVariant(variant: null | Variant | ErrorCapsule) {
  return {
    type: ACTION_SET_VARIANT,
    variant,
  };
}

function setPrinters(printers: Array<Printer>) {
  return {
    type: ACTION_SET_PRINTERS,
    printers,
  };
}

function setTestCut(testCut: null | Cut | ErrorCapsule) {
  return {
    type: ACTION_SET_TEST_CUT,
    testCut,
  };
}

async function loadVariant(id: string) {
  try {
    const variant = await getVariant(id, variantShape);

    return setVariant(variant);
  } catch (err) {
    return setVariant(new ErrorCapsule(err, () => [
      setVariant(initialState.variant),
      loadVariant(id),
    ]));
  }
}

async function loadPrinters() {
  try {
    const printers = await listPrinters({}, '{ id name }');

    return setPrinters(printers);
  } catch (err) {
    return setPrinters(new ErrorCapsule(err, () => [
      setPrinters(null),
      loadPrinters(),
    ]));
  }
}

async function startTestCut(variantId: string, printerId: string) {
  try {
    const cut = await createCut({ variantId, printerId }, '{ id status }');

    return setTestCut(cut);
  } catch (err) {
    return setTestCut(new ErrorCapsule(err));
  }
}

export function variantReducer(
  state: VariantState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_VARIANT:
      return {
        ...state,
        variant: action.variant,
      };

    case ACTION_SET_PRINTERS:
      return {
        ...state,
        printers: action.printers,
      };

    case ACTION_SET_TEST_CUT:
      return {
        ...state,
        testCut: action.testCut,
      };

    default:
      return state;
  }
}

const VariantPage = connect(
  ({ library }): VariantPageViewProps => library.variant,
)(class extends Component<VariantPageViewProps> {
  constructor(props) {
    const { id, dispatch } = props;
    super(props);

    dispatch([
      setVariant(null),
      setTestCut(null),
      loadVariant(id),
      loadPrinters(),
    ]);
  }

  render() {
    const { dispatch } = this.props;
    return (
      <VariantPageView
        {...this.props}
        onTestCut={(variantId, pritnerId) => dispatch(startTestCut(variantId, pritnerId))}
      />
    );
  }
});

export function variantPageRoutes(): Routes {
  return [
    {
      title: 'Вариант',
      path: '/variant/:id/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <VariantPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
