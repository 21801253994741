// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { printersListReducer } from './PrintersListPage';
import { printerReducer } from './PrinterPage';
import { addPrinterReducer } from './AddPrinterPage';
import { editPrinterReducer } from './EditPrinterPage';
import { cutStatusReducer } from './CutStatusWidget';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'print';

export const reducer = safeCombineReducers({
  printersList: printersListReducer,
  printer: printerReducer,
  addPrinter: addPrinterReducer,
  editPrinter: editPrinterReducer,
  cutStatus: cutStatusReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
