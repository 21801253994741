// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DefaultForm, DefaultFormDropDown,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText, DefaultFormTextArea,
} from '../../elements/DefaultForm';

import type { Office } from '../model/Office.model';
import { officeMetaModel } from '../model/Office.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Franchisee } from '../model/Franchisee.model';

export type OfficeEditPageViewProps = {
  office: null | Office | ErrorCapsule,
  franchisees: null | Array<Franchisee> | ErrorCapsule,
  onSubmit: (values: { [string]: any }) => void,
}

export default function OfficeEditPageView({
  office,
  franchisees,
  onSubmit,
}: OfficeEditPageViewProps) {
  const { t } = useTranslation();

  if (office instanceof ErrorCapsule) return <LoadFailed error={office} />;
  if (franchisees instanceof ErrorCapsule) return <LoadFailed error={franchisees} />;
  if (!office || !franchisees) return <Loader />;
  const isCreating = office.id == null;

  const pageTitle = isCreating
    ? 'Добавление офиса'
    : `Редактирование ${office.title}`;

  return (
    <div className="office-edit">
      <h1>{pageTitle}</h1>
      <DefaultForm
        form="officeEdit"
        metaModel={officeMetaModel}
        initialValues={office}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormDropDown
          autoFocus
          attribute="franchiseeId"
          options={[['', 'Свой Офис'], ...franchisees.map(({ id, title }) => [id, title])]}
        />
        <DefaultFormText attribute="title" />
        <DefaultFormTextArea attribute="notes" />
        <DefaultFormSubmit label={isCreating ? 'Добавить' : 'Изменить'} />
      </DefaultForm>
    </div>
  );
}
