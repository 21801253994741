// @flow
import React, { Component } from 'react';
import { Router } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import './theme/app.scss';
import { getHistory } from './data/router.redux';
import { DialogsProvider } from '../elements/Dialog';

class App extends Component<{}> {
  render() {
    return (
      <Router history={getHistory()}>
        <DialogsProvider>
          <MainLayout />
        </DialogsProvider>
      </Router>
    );
  }
}

export default App;
