// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { printerMetaModel } from './Printer.model';

export function filterPrinterInput(values) {
  return _pickBy(values, (_, key) => key in printerMetaModel.rules || key === 'id');
}

export function listPrinters(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: PrintersFilter) {
        listPrinters(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getPrinter(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getPrinter(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createPrinter(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PrinterCreate!) {
        createPrinter(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updatePrinter(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PrinterUpdate!) {
        updatePrinter(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
