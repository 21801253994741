// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import {
  getOffice,
  createOffice,
  updateOffice,
  filterOfficeInput,
} from './model/Office.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import OfficeEditPageView from './views/OfficeEditPageView';
import type { OfficeEditPageViewProps } from './views/OfficeEditPageView';
import type { Office } from './model/Office.model';
import { getAllFranchisees, loadAllFranchisees } from './redux/Franchisee.redux';

type OfficeEditState = {
  office: null | Office | ErrorCapsule,
}

const officeShape = `{
  id
  franchiseeId
  franchisee {
    id
    title
    notes
  }
  title
  notes
}`;

const initialState: OfficeEditState = {
  office: null,
};

const ACTION_SET_OFFICE = 'org/officeEdit/ACTION_SET_OFFICE';

function setOffice(office: null | Office | ErrorCapsule) {
  return {
    type: ACTION_SET_OFFICE,
    office,
  };
}

async function loadOffice(id: string) {
  try {
    const office = await getOffice(id, officeShape);

    return setOffice(office);
  } catch (err) {
    return setOffice(new ErrorCapsule(err, () => [
      setOffice(initialState.office),
      loadOffice(id),
    ]));
  }
}

export async function saveOffice(values) {
  const office = !values.id
    ? await createOffice(filterOfficeInput(values), officeShape)
    : await updateOffice(filterOfficeInput(values), officeShape);

  return [
    setOffice(office),
    redirect('/org/offices/'),
  ];
}

export function officeEditReducer(
  state: OfficeEditState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_OFFICE:
      return {
        ...state,
        office: action.office,
      };

    default:
      return state;
  }
}

const OfficeEditPage = connect(
  (state): OfficeEditPageViewProps => ({
    ...state.org.officeEdit,
    franchisees: getAllFranchisees(state),
  }),
)(class extends Component<OfficeEditPageViewProps> {
  constructor(props) {
    const { id, franchisees, dispatch } = props;
    super(props);

    dispatch([
      ...(!franchisees ? [loadAllFranchisees()] : []),
      ...(id ? [
        setOffice(null),
        loadOffice(id),
      ] : [
        setOffice({}),
      ]),
    ]);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setOffice(null));
  }

  render() {
    const { dispatch } = this.props;
    return (
      <OfficeEditPageView
        {...this.props}
        onSubmit={(values: Office) => dispatch(saveOffice(values))}
      />
    );
  }
});

export function officeEditPageRoutes(): Routes {
  return [
    {
      title: 'Добавление офиса',
      path: '/office-add/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <OfficeEditPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
    {
      title: 'Изменение офиса',
      path: '/office-edit/:id/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <OfficeEditPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
