// @flow
import { required } from 'redux-form-validators';
import { string, boolean } from '../../core/data/rules';
import type { MetaModel } from '../../core/data/rules';

export type Franchisee = {|
  id: string,
  title: string,
  notes: ?string,
  isDeleted: boolean,
|}


export const franchiseeMetaModel: MetaModel = {
  name: 'Franchisee',
  rules: {
    title: [string(), required()],
    notes: [string()],
    isDeleted: [boolean(), required()],
  },
  labels: {
    title: 'Название',
    notes: 'Заметки',
  },
};
