// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { Printer } from './model/Printer.model';
import {
  getPrinter,
  createPrinter,
  updatePrinter,
  filterPrinterInput,
} from './model/Printer.client';
import { redirect } from '../core/data/router.redux';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import EditPrinterPageView from './views/EditPrinterPageView';

type EditPrinterState = {
  printer: null | Printer | ErrorCapsule,
}

const printerShape = `{
  id
  officeId
  office {
    title
    franchisee {
      title
    }
  }
  name
  model
  commandsVersion
  printUpc
  printSpeed
  printPressure
  printRepeats
}`;

const initialState: EditPrinterState = {
  printer: null,
};

const ACTION_SET_PRINTER = 'print/editPrinter/ACTION_SET_PRINTER';

function setPrinter(printer: null | Printer | ErrorCapsule) {
  return {
    type: ACTION_SET_PRINTER,
    printer,
  };
}

async function loadPrinter(id: string) {
  try {
    const printer = await getPrinter(id, printerShape);

    return setPrinter(printer);
  } catch (err) {
    return setPrinter(new ErrorCapsule(err, () => [
      setPrinter(initialState.printer),
      loadPrinter(id),
    ]));
  }
}

export async function savePrinter(values) {
  const printer = !values.id
    ? await createPrinter(filterPrinterInput(values), printerShape)
    : await updatePrinter(filterPrinterInput(values), printerShape);

  return [
    setPrinter(printer),
    redirect(`/print/printers/${printer.id}/`),
  ];
}

export function editPrinterReducer(
  state: EditPrinterState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_PRINTER:
      return {
        ...state,
        printer: action.printer,
      };

    default:
      return state;
  }
}

type Props = {
  id: string,
}

function EditPrinterPage({
  id,
}: Props) {
  const dispatch = useDispatch();
  const {
    printer,
  }: EditPrinterState = useSelector(({ print }) => print.editPrinter);

  // Load Printer
  useEffect(() => {
    dispatch([
      setPrinter(null),
      loadPrinter(id),
    ]);
    return () => dispatch(setPrinter(null));
  }, [id]);

  // Render
  return (
    <EditPrinterPageView
      printer={printer}
      onSubmit={
        ({
          officeId,
          name,
          model,
          printUpc,
          printSpeed,
          printPressure,
          printRepeats,
          ...values
        }: Printer) => dispatch(savePrinter({
          printUpc: Number(printUpc),
          printSpeed: Number(printSpeed),
          printPressure: Number(printPressure),
          printRepeats: Number(printRepeats),
          ...values,
        }))
      }
    />
  );
}

export function editPrinterPageRoutes(): Routes {
  return [
    {
      title: 'Настройка плоттера',
      path: '/printers/:id/edit',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <EditPrinterPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
