// @flow
import { date, required } from 'redux-form-validators';
import { string } from '../../core/data/rules';
import type { MetaModel } from '../../core/data/rules';

export type NewsArticle = {|
  id: string,
  date: string,
  text: string,
|}


export const newsArticleMetaModel: MetaModel = {
  name: 'NewsArticle',
  rules: {
    date: [date({ format: 'yyyy-mm-dd' }), required()],
    text: [string(), required()],
  },
  labels: {
    date: 'Дата',
    text: 'Текст',
  },
};
