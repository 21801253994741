// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _omit from 'lodash/omit';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { listBrands } from '../library/model/Brand.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import CutPageBrandsView from './views/CutPageBrandsView';
import type { Brand } from '../library/model/Brand.model';
import type { Device } from '../library/model/Device.model';
import Loader from '../elements/Loader';
import LoadFailed from '../elements/LoadFailed';
import CutPageDevicesView from './views/CutPageDevicesView';
import CutPageWorkView from './views/CutPageWorkView';
import Error404Page from '../statics/Error404Page';
import { createCut } from '../print/model/Cut.client';
import type { Cut } from '../print/model/Cut.model';
import { getSelectedPrinter } from './SelectPrinterWidget';

type CutState = {
  data: null | Array<Brand> | ErrorCapsule,
  selectedVariantId: ?string,
  currentCut: ?Cut,
}

const dataShape = `{
  id
  name
  logo {
    id
    fit(width: 70, height: 76) { width height url }
  }
  devices(filter: { _sort: "name" } ) {
    id
    deviceType { id name }
    name
    preview {
      id
      fit(width: 70, height: 76) { width height url }
    }
    variants(filter: { _sort: "name" } ) {
      id
      name
      preview {
        id
        fit(width: 100, height: 100) { width height url }
      }
    }
  }
}`;

const initialState: CutState = {
  data: null,
  selectedVariantId: null,
  currentCut: null,
};

const ACTION_SET_STATE = 'frontdesk/cut/ACTION_SET_STATE';

function setState(stateDelta: Object) {
  return {
    type: ACTION_SET_STATE,
    stateDelta,
  };
}

async function loadData() {
  try {
    const rawData = await listBrands({ _sort: 'name' }, dataShape);

    // Filter empty entries
    const data = _filter(rawData, brand => {
      // eslint-disable-next-line no-param-reassign
      brand.devices = _filter(brand.devices, device => device.variants.length > 0);
      return brand.devices.length > 0;
    });

    return setState({ data });
  } catch (err) {
    return setState(new ErrorCapsule(err, () => [
      setState({ data: null }),
      loadData(),
    ]));
  }
}

async function print(printerId: string, variantId: string) {
  try {
    const cut = await createCut({ printerId, variantId }, '{ id status }');

    return setState({ currentCut: cut });
  } catch (err) {
    return setState({
      currentCut: new ErrorCapsule(err, () => [
        setState({ currentCut: null }),
        print(printerId, variantId),
      ]),
    });
  }
}

export function cutReducer(
  state: CutState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_STATE:
      return {
        ...state,
        ...action.stateDelta,
      };

    default:
      return state;
  }
}

type Props = {
  brandId?: string,
  deviceId?: string,
}

function CutPage({ brandId, deviceId }: Props) {
  const dispatch = useDispatch();
  const {
    data,
    selectedVariantId,
    currentCut,
  }: CutState = useSelector(({ frontdesk }) => frontdesk.cut);
  const selectedPrinter = useSelector(getSelectedPrinter);

  // Init state
  useEffect(() => {
    if (!data) {
      dispatch(loadData());
    }
    // On close reset state, but preserve data
    return () => dispatch(setState(_omit(initialState, ['data'])));
  }, []);

  // Require data
  if (!data) return <Loader />;
  if (data instanceof ErrorCapsule) return <LoadFailed error={data} />;

  // Decode brand
  let brand: Brand;
  if (brandId) {
    brand = _find(data, ({ id }) => id === brandId);
    if (!brand) {
      return <Error404Page />;
    }
  }

  // Decode device
  let device: Device;
  if (deviceId) {
    device = _find(brand.devices, ({ id }) => id === deviceId);
    if (!device) {
      return <Error404Page />;
    }
  }

  // Render work page
  if (device) {
    const isSelectedGood = selectedVariantId
      && _find(device.variants, ({ id }) => id === selectedVariantId);
    return (
      <CutPageWorkView
        brand={brand}
        device={device}
        variants={device.variants}
        selectedVariantId={isSelectedGood ? selectedVariantId : device.variants[0].id}
        canPrint={selectedPrinter != null}
        currentCut={currentCut}
        onSelectVariant={(id) => dispatch(setState({ selectedVariantId: id }))}
        onPrint={(variantId) => dispatch(print(selectedPrinter.id, variantId))}
      />
    );
  }

  // Render pick device
  if (brand) {
    return (
      <CutPageDevicesView
        brand={brand}
        devices={brand.devices}
      />
    );
  }

  // Render pick brand
  return (
    <CutPageBrandsView
      brands={data}
    />
  );
}

export function cutPageRoutes(): Routes {
  return [
    {
      title: 'Выбор марки',
      path: '/',
      isEnabled: ({ isUser }) => isUser,
      nav: { showNews: true },
      render: params => (
        <CutPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
    },
    {
      title: 'Выбор модели',
      path: '/:brandId/',
      isEnabled: ({ isUser }) => isUser,
      nav: { showNews: true },
      render: params => (
        <CutPage
          {...sanitizeRouteParams(params, {
            brandId: 'id',
          })}
        />
      ),
    },
    {
      title: 'Рез',
      path: '/:brandId/:deviceId/',
      isEnabled: ({ isUser }) => isUser,
      nav: { showNews: true },
      render: params => (
        <CutPage
          {...sanitizeRouteParams(params, {
            brandId: 'id',
            deviceId: 'id',
          })}
        />
      ),
    },
  ];
}
