// @flow
import React, { useContext, useState } from 'react';
import _filter from 'lodash/filter';
import DialogWidgetView, { ConfirmFooter } from './views/DialogWidgetView';

const DialogContext = React.createContext();

export function useDialog() {
  const { add } = useContext(DialogContext);
  return {
    confirm({ renderBody, handler }) {
      add({
        renderBody,
        renderFooter: props => <ConfirmFooter {...props} handler={handler} />,
        handler,
      });
    },
  };
}

let lastId = 0;

type Props = {
  children: React$Node,
};

export function DialogsProvider({ children }: Props) {
  const [dialogs, setDialogs] = useState([]);

  function close(propsKey) {
    setDialogs(_filter(dialogs, ({ key }) => key !== propsKey));
  }

  function add(props) {
    const key = ++lastId;
    setDialogs([
      ...dialogs,
      {
        ...props,
        key,
        onClose: () => close(key),
      },
    ]);
  }

  return (
    <DialogContext.Provider value={{ add }}>
      {children}
      {dialogs.map(props => <DialogWidgetView {...props} />)}
    </DialogContext.Provider>
  );
}
