// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required, numericality } from 'redux-form-validators';
import { string, boolean } from '../../core/data/rules';
import type { Office } from '../../org/model/Office.model';
import type { MetaModel } from '../../core/data/rules';

export type Printer = {|
  id: string,
  officeId: string,
  office: Office,
  clientId: string,
  printerLocalId: string,
  name: string,
  model: string,
  commandsVersion: string,
  printUpc: number,
  printSpeed: number,
  printPressure: number,
  printRepeats: number,
  isDeleted: boolean,
|}


export const printerMetaModel: MetaModel = {
  name: 'Printer',
  rules: {
    officeId: [string(), required()],
    clientId: [string(), required()],
    printerLocalId: [string(), required()],
    name: [string(), required()],
    model: [string(), required()],
    commandsVersion: [string(), required()],
    printUpc: [numericality({ int: true }), required()],
    printSpeed: [numericality({ int: true }), required()],
    printPressure: [numericality({ int: true }), required()],
    printRepeats: [numericality({ int: true }), required()],
    isDeleted: [boolean(), required()],
  },
};
