// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { getPrinter } from './model/Printer.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import PrinterPageView from './views/PrinterPageView';
import type { PrinterPageViewProps } from './views/PrinterPageView';
import type { Printer } from './model/Printer.model';
import { createCut } from './model/Cut.client';
import type { Cut } from './model/Cut.model';

type PrinterState = {
  printer: null | Printer | ErrorCapsule,
  testCut: null | Cut | ErrorCapsule,
}

const printerShape = `{
  id
  name
  model
  commandsVersion
  printUpc
  printSpeed
  printPressure
  printRepeats
}`;

const initialState: PrinterState = {
  printer: null,
  testCut: null,
};

const ACTION_SET_PRINTER = 'print/printer/ACTION_SET_PRINTER';
const ACTION_SET_TEST_CUT = 'print/printer/ACTION_SET_TEST_CUT';

function setPrinter(printer: null | Printer | ErrorCapsule) {
  return {
    type: ACTION_SET_PRINTER,
    printer,
  };
}

function setTestCut(testCut: null | Cut | ErrorCapsule) {
  return {
    type: ACTION_SET_TEST_CUT,
    testCut,
  };
}

async function loadPrinter(id: string) {
  try {
    const printer = await getPrinter(id, printerShape);

    return setPrinter(printer);
  } catch (err) {
    return setPrinter(new ErrorCapsule(err, () => [
      setPrinter(initialState.printer),
      loadPrinter(id),
    ]));
  }
}

async function startTestCut(printerId: string) {
  try {
    const cut = await createCut({ printerId }, '{ id status }');

    return setTestCut(cut);
  } catch (err) {
    return setTestCut(new ErrorCapsule(err));
  }
}

export function printerReducer(
  state: PrinterState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_PRINTER:
      return {
        ...state,
        printer: action.printer,
      };

    case ACTION_SET_TEST_CUT:
      return {
        ...state,
        testCut: action.testCut,
      };

    default:
      return state;
  }
}

const PrinterPage = connect(
  ({ print }): PrinterPageViewProps => ({
    printer: print.printer.printer,
    testCut: print.printer.testCut,
  }),
)(class extends Component<PrinterPageViewProps> {
  constructor(props) {
    const { id, dispatch } = props;
    super(props);

    dispatch([
      setPrinter(null),
      setTestCut(null),
      loadPrinter(id),
    ]);
  }

  render() {
    const { id, dispatch } = this.props;
    return (
      <PrinterPageView
        {...this.props}
        onTestCut={() => dispatch(startTestCut(id))}
      />
    );
  }
});

export function printerPageRoutes(): Routes {
  return [
    {
      title: 'Принтер',
      path: '/printers/:id/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <PrinterPage
          {...sanitizeRouteParams(params, {
            id: 'id',
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
