// @flow
import { AnyAction } from 'redux';
import { listOffices } from '../model/Office.client';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Office } from '../model/Office.model';

type OfficeState = {
  all: Array<Office>,
}

const initialState: OfficeState = {
  all: null,
};

const ACTION_SET_ALL = 'org/Office/ACTION_SET_ALL';

function setAll(all) {
  return {
    type: ACTION_SET_ALL,
    all,
  };
}

export function officeReducer(
  state: OfficeState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_ALL:
      return {
        ...state,
        all: action.all,
      };

    default:
      return state;
  }
}

export function getAllOffices(reduxState) {
  return reduxState.org.office.all;
}

export async function loadAllOffices() {
  try {
    const offices = await listOffices({ isDeleted: false }, '{ id title franchisee { id title } }');

    return setAll(offices);
  } catch (err) {
    return setAll(new ErrorCapsule(err, () => [
      setAll(initialState.all),
      loadAllOffices(),
    ]));
  }
}
