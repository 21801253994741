// @flow
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { franchiseeReducer } from './redux/Franchisee.redux';
import { officeReducer } from './redux/Office.redux';
import { franchiseesListReducer } from './FranchiseesListPage';
import { franchiseeEditReducer } from './FranchiseeEditPage';
import { officesListReducer } from './OfficesListPage';
import { officeEditReducer } from './OfficeEditPage';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'org';

export const reducer = safeCombineReducers({
  franchisee: franchiseeReducer,
  office: officeReducer,
  franchiseesList: franchiseesListReducer,
  franchiseeEdit: franchiseeEditReducer,
  officesList: officesListReducer,
  officeEdit: officeEditReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
