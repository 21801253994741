// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import gql from 'graphql-tag';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import _filter from 'lodash/filter';
import { NewsArticle } from './model/NewsArticle.model';
import { listNewsArticles } from './model/NewsArticle.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';
import NewsListPageView from './views/NewsListPageView';
import { apolloMutate } from '../core/data/apolloClient';

type NewsListState = {
  newsArticles: null | Array<NewsArticle> | ErrorCapsule,
}

const newsArticleShape = `{
  id
  date
  text
}`;

const initialState: NewsListState = {
  newsArticles: null,
};

const ACTION_SET_NEWS_ARTICLES = 'library/newsList/ACTION_SET_NEWS_ARTICLES';
const ACTION_DELETE_NEWS_ARTICLE = 'library/newsList/ACTION_DELETE_NEWS_ARTICLE';

function setNewsArticles(newsArticles: null | Array<NewsArticle> | ErrorCapsule) {
  return {
    type: ACTION_SET_NEWS_ARTICLES,
    newsArticles,
  };
}

async function loadNewsArticles(filter?: ?{ [string]: string }) {
  try {
    const newsArticles = await listNewsArticles(filter, newsArticleShape);

    return setNewsArticles(newsArticles);
  } catch (err) {
    return setNewsArticles(new ErrorCapsule(err, () => [
      setNewsArticles(initialState.newsArticles),
      loadNewsArticles(filter),
    ]));
  }
}

async function deleteNewsArticle(id) {
  await apolloMutate(
    gql`
      mutation ($id: String!) {
        deleteNewsArticle(id: $id)
      }
    `,
    { id }
  );

  return {
    type: ACTION_DELETE_NEWS_ARTICLE,
    id,
  };
}

export function newsListReducer(
  state: NewsListState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_NEWS_ARTICLES:
      return {
        ...state,
        newsArticles: action.newsArticles,
      };

    case ACTION_DELETE_NEWS_ARTICLE:
      return {
        ...state,
        newsArticles: _filter(state.newsArticles, ({ id }) => id !== action.id),
      };

    default:
      return state;
  }
}

type Props = {}

function NewsListPage({}: Props) {
  const dispatch = useDispatch();
  const { newsArticles }: NewsListState = useSelector(({ library }) => library.newsList);

  // Load NewsArticles
  useEffect(() => {
    dispatch([
      setNewsArticles(null),
      loadNewsArticles(/* { field: mode } */),
    ]);
    return () => dispatch(setNewsArticles(null));
  }, []);

  // Render
  return (
    <NewsListPageView
      newsArticles={newsArticles}
      onDeleteNewsArticle={(id) => dispatch(deleteNewsArticle(id))}
    />
  );
}

export function newsListPageRoutes(): Routes {
  return [
    {
      title: 'Новости',
      path: '/news/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <NewsListPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
