// @flow
import _find from 'lodash/find';
import type { Model } from './rules';
import UnexpectedCaseException from '../exceptions/UnexpectedCaseException';
import ErrorCapsule from '../exceptions/ErrorCapsule';

type Unsubscribe = () => void;

export type CollectionChange = {
  action: 'create',
  preId: string,
  model: Model,
} | {
  action: 'update',
  model: Model,
} | {
  action: 'delete',
  id: string,
}

type Params = {
  // Watch
  list: () => Promise<[Model]>,
  subscribe: (listener: CollectionChange => void) => Unsubscribe,
  listener: ([Model]) => void,

  // Mutate
  extendPreModel?: (input: Model) => Model,
  create?: (preId: string, input: Model) => Promise<[Model]>,
  update?: (input: Model) => Promise<[Model]>,
  del?: (id: string) => Promise<void>,
  insert?: (list: [Model], model: Model) => [Model],
}

let lastId = 0;

function newPreId() {
  lastId++;
  return 'pre-' + lastId;
}


export default class RealtimeCollection {
  _unsubscribe;

  _list = null;

  +_params: Params;

  constructor(params: Params) {
    this._params = params;
  }

  toSubscription() {
    const openPromise = this.open();

    // Return unsubscribe
    return async () => {
      await openPromise;
      this.close();
    };
  }

  async open() {
    if (this._unsubscribe) {
      throw new UnexpectedCaseException();
    }

    const {
      list,
      subscribe,
      listener,
    } = this._params;

    const queue = [];

    // Subscribe first
    this._unsubscribe = await subscribe((change: CollectionChange) => {
      if (this._list) {
        this._handleChange(change);
        listener(this._list);
      } else {
        queue.push(change);
      }
    });
    try {

      // Initial load
      this._list = await list();
      queue.forEach(change => this._handleChange(change));
      listener(this._list);

    } catch (e) {
      this._unsubscribe();
      this._unsubscribe = null;
      throw e;
    }
  }

  safeOpen(catcher) {
    this.open()
      .catch(error => {
        catcher(new ErrorCapsule(
          error,
          () => this.safeOpen(catcher)
        ));
      });
  }

  close() {
    // Don't call this twice
    if (!this._unsubscribe) {
      throw new UnexpectedCaseException();
    }
    this._unsubscribe();
    this._unsubscribe = null;
    this._list = null;
  }

  async create(input: Model): Model {
    // Generate required auto fields
    const preModelData = this._params.extendPreModel
      ? this._params.extendPreModel(input)
      : input;

    // Form preModel
    const preId = newPreId();
    const preModel = {
      ...preModelData,
      id: preId,
    };

    // Show in UI
    this._setListAndEmit([...this._list, preModel]);

    // Send to backend
    const model = await this._params.create(preId, input);

    // Fresh model
    this._handleCreate(preId, model);
    this._params.listener(this._list);

    return model;
  }

  _setListAndEmit(newList) {
    this._list = newList;
    this._params.listener(newList);
  }

  _handleChange({ action, preId, model, id: deleteId }: CollectionChange) {
    switch (action) {
      case 'create':
        this._handleCreate(preId, model);
        break;
      case 'update':
        this._list = this._list.map(item => (
          item.id !== model.id
            ? item
            : { ...item, ...model }
        ));
        break;
      case 'delete':
        this._list = this._list.filter(({ id }) => id !== deleteId);
        break;
      default:
        throw new UnexpectedCaseException();
    }
  }

  _handleCreate(preId, model) {
    let replaced = false;

    // Find preModel. That means the creation was committed by our client
    this._list = this._list.map(item => {
      if (item.id === preId) {
        replaced = true;
        return model;
      }
      return item;
    });

    // Add, if not added by a parallel source (e.g. subscription vs. mutation response)
    if (!replaced && !_find(this._list, item => item.id === model.id)) {
      if (this._params.insert) {
        this._list = this._params.insert(this._list, model);
      } else {
        this._list = [...this._list, model];
      }
    }
  }
}
