// @flow
import { required } from 'redux-form-validators';
import { string, boolean } from '../../core/data/rules';
import type { Franchisee } from './Franchisee.model';
import type { MetaModel } from '../../core/data/rules';

export type Office = {|
  id: string,
  franchiseeId: ?string,
  franchisee: ?Franchisee,
  title: string,
  notes: ?string,
  isDeleted: boolean,
|}


export const officeMetaModel: MetaModel = {
  name: 'Office',
  rules: {
    franchiseeId: [string()],
    title: [string(), required()],
    notes: [string()],
    isDeleted: [boolean(), required()],
  },
  labels: {
    franchiseeId: 'Франчайзи',
    title: 'Название',
    notes: 'Заметки',
  },
};
