// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { supportMocking } from 'metaup/dev/supportMocking';
import { NewsArticle } from './model/NewsArticle.model';
import { listNewsArticles } from './model/NewsArticle.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import NewsWidgetView from './views/NewsWidgetView';

type NewsState = {
  newsArticles: null | Array<NewsArticle> | ErrorCapsule,
}

const newsArticleShape = `{
  id
  date
  text
}`;

const initialState: NewsState = {
  newsArticles: null,
};

const ACTION_SET_NEWS_ARTICLES = 'library/news/ACTION_SET_NEWS_ARTICLES';

function setNewsArticles(newsArticles: null | Array<NewsArticle> | ErrorCapsule) {
  return {
    type: ACTION_SET_NEWS_ARTICLES,
    newsArticles,
  };
}

async function loadNewsArticles(filter?: ?{ [string]: string }) {
  try {
    const newsArticles = await listNewsArticles(filter, newsArticleShape);

    return setNewsArticles(newsArticles);
  } catch (err) {
    return setNewsArticles(new ErrorCapsule(err, () => [
      setNewsArticles(initialState.newsArticles),
      loadNewsArticles(filter),
    ]));
  }
}

export function newsReducer(
  state: NewsState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_NEWS_ARTICLES:
      return {
        ...state,
        newsArticles: action.newsArticles,
      };

    default:
      return state;
  }
}

type Props = {}

function NewsWidget({}: Props) {
  const dispatch = useDispatch();
  const {
    newsArticles,
  }: NewsState = useSelector(({ library }) => library.news);

  // Load NewsArticles
  useEffect(() => {
    dispatch([
      setNewsArticles(null),
      loadNewsArticles(/* { field: mode } */),
    ]);
    return () => dispatch(setNewsArticles(null));
  }, []);

  // Render
  return (
    <NewsWidgetView
      newsArticles={newsArticles}
    />
  );
}

export default supportMocking(NewsWidget, NewsWidgetView);
