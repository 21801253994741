// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required } from 'redux-form-validators';
import { string } from '../../core/data/rules';
import type { User } from '../../users/model/User.model';
import type { Printer } from './Printer.model';
import type { Variant } from '../../library/model/Variant.model';
import type { MetaModel } from '../../core/data/rules';

export type Cut = {|
  id: string,
  creatorId: string,
  creator: User,
  madeAt: string,
  status: string,
  statusMessage: ?string,
  printerId: string,
  printer: Printer,
  variantId: ?string,
  variant: ?Variant,
|}


export const cutMetaModel: MetaModel = {
  name: 'Cut',
  rules: {
    creatorId: [string(), required()],
    madeAt: [string(), required()],
    status: [string(), required()],
    statusMessage: [string()],
    printerId: [string(), required()],
    variantId: [string()],
  },
};
