// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import _find from 'lodash/find';
import { useDispatch, useSelector } from 'react-redux';
import { supportMocking } from 'metaup/dev/supportMocking';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import SelectPrinterWidgetView from './views/SelectPrinterWidgetView';
import type { Printer } from '../print/model/Printer.model';
import { listPrinters } from '../print/model/Printer.client';

type SelectPrinterState = {
  officePrinters: null | Array<Printer> | ErrorCapsule,
  selectedPrinter: ?Printer,
}

const initialState: SelectPrinterState = {
  officePrinters: null,
  selectedPrinter: null,
};

const ACTION_SET_STATE = 'frontdesk/selectPrinter/ACTION_SET_STATE';

function setState(diff: any) {
  return {
    type: ACTION_SET_STATE,
    diff,
  };
}

async function loadOfficePrinters() {
  try {
    const officePrinters = await listPrinters(null, '{ id name }');

    return setState({ officePrinters });
  } catch (err) {
    return setState(new ErrorCapsule(err, () => [
      setState(initialState),
      loadOfficePrinters(),
    ]));
  }
}

function adjustSelected(state: SelectPrinterState): SelectPrinterState {
  if (!Array.isArray(state.officePrinters)) {
    return { ...state, selectedPrinter: null };
  }
  if (state.officePrinters.length === 0) {
    return { ...state, selectedPrinter: null };
  }
  if (state.officePrinters.length === 1) {
    return { ...state, selectedPrinter: state.officePrinters[0] };
  }
  return { ...state, selectedPrinter: state.selectedPrinter || state.officePrinters[0] };
}


export function selectPrinterReducer(
  state: SelectPrinterState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_STATE:
      return adjustSelected({
        ...state,
        ...action.diff,
      });

    default:
      return state;
  }
}

export function getSelectedPrinter(reduxState): Printer {
  return reduxState.frontdesk.selectPrinter.selectedPrinter;
}

type Props = {};

function SelectPrinterWidget({}: Props) {
  const dispatch = useDispatch();
  const {
    officePrinters,
    selectedPrinter,
  }: SelectPrinterState = useSelector(({ frontdesk }) => frontdesk.selectPrinter);

  useEffect(() => {
    dispatch(loadOfficePrinters());
    return () => dispatch(setState(initialState));
  }, []);

  return (
    <SelectPrinterWidgetView
      officePrinters={officePrinters}
      selectedPrinter={selectedPrinter}
      onChange={printerId => dispatch(setState({
        selectedPrinter: _find(officePrinters, ({ id }) => id === printerId),
      }))}
    />
  );
}

export default supportMocking(SelectPrinterWidget, SelectPrinterWidgetView);
