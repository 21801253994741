// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { brandMetaModel } from './Brand.model';

export function filterBrandInput(values) {
  return _pickBy(values, (_, key) => key in brandMetaModel.rules || key === 'id');
}

export function listBrands(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: BrandsFilter) {
        listBrands(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getBrand(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getBrand(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createBrand(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: BrandCreate!) {
        createBrand(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updateBrand(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: BrandUpdate!) {
        updateBrand(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
