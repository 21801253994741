// @flow
import { required } from 'redux-form-validators';
import { string } from '../../core/data/rules';
import type { User } from '../../users/model/User.model';
import type { MetaModel } from '../../core/data/rules';

export type Auth = {|
  id: string,
  userId: string,
  user: User,
  provider: string,
  password: ?string,
  socialId: ?string,
  extra: any,
|}


export const authMetaModel: MetaModel = {
  name: 'Auth',
  rules: {
    userId: [string(), required()],
    provider: [string(), required()],
    password: [string()],
    socialId: [string()],
    extra: [],
  },
  labels: {
    password: 'Пароль',
  },
};
