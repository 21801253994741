// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import _filter from 'lodash/filter';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { listPrinters } from './model/Printer.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import PrintersListPageView from './views/PrintersListPageView';
import type { PrintersListPageViewProps } from './views/PrintersListPageView';
import type { Printer } from './model/Printer.model';
import { apolloMutate } from '../core/data/apolloClient';

type PrintersListState = {
  printers: null | Array<Printer> | ErrorCapsule,
}

const printerShape = `{
  id
  name
  model
  office {
    id
    title
    franchisee { id title }
  }
}`;

const initialState: PrintersListState = {
  printers: null,
};

const ACTION_SET_PRINTERS = 'print/printersList/ACTION_SET_PRINTERS';
const ACTION_DELETE_PRINTER = 'print/printersList/ACTION_DELETE_PRINTER';

function setPrinters(printers: null | Array<Printer> | ErrorCapsule) {
  return {
    type: ACTION_SET_PRINTERS,
    printers,
  };
}

async function loadPrinters(filter?: ?{ [string]: string }) {
  try {
    const printers = await listPrinters(filter, printerShape);

    return setPrinters(printers);
  } catch (err) {
    return setPrinters(new ErrorCapsule(err, () => [
      setPrinters(initialState.printers),
      loadPrinters(filter),
    ]));
  }
}

async function deletePrinter(id) {
  await apolloMutate(
    gql`
      mutation ($id: String!) {
        deletePrinter(id: $id)
      }
    `,
    { id }
  );

  return {
    type: ACTION_DELETE_PRINTER,
    id,
  };
}

export function printersListReducer(
  state: PrintersListState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_PRINTERS:
      return {
        ...state,
        printers: action.printers,
      };

    case ACTION_DELETE_PRINTER:
      return {
        ...state,
        printers: _filter(state.printers, ({ id }) => id !== action.id),
      };

    default:
      return state;
  }
}

const PrintersListPage = connect(
  ({ print }): PrintersListPageViewProps => ({
    printers: print.printersList.printers,
  }),
)(class extends Component<PrintersListPageViewProps> {
  constructor(props) {
    const { dispatch } = props;
    super(props);

    dispatch(loadPrinters(/* { field: mode } */));
  }

  render() {
    const { dispatch } = this.props;
    return (
      <PrintersListPageView
        {...this.props}
        onDeletePrinter={id => dispatch(deletePrinter(id))}
      />
    );
  }
});

export function printersListPageRoutes(): Routes {
  return [
    {
      title: 'Принтеры',
      path: '/printers/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <PrintersListPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
