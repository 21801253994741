// @flow
import React, { Component } from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import _filter from 'lodash/filter';
import { listOffices } from './model/Office.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import OfficesListPageView from './views/OfficesListPageView';
import type { OfficesListPageViewProps } from './views/OfficesListPageView';
import type { Office } from './model/Office.model';
import { apolloMutate } from '../core/data/apolloClient';

type OfficesListState = {
  offices: null | Array<Office> | ErrorCapsule,
}

const officeShape = `{
  id
  franchiseeId
  franchisee {
    id
    title
    notes
  }
  title
  notes
}`;

const initialState: OfficesListState = {
  offices: null,
};

const ACTION_SET_OFFICES = 'org/officesList/ACTION_SET_OFFICES';
const ACTION_DELETE_OFFICE = 'org/officesList/ACTION_DELETE_OFFICE';

function setOffices(offices: null | Array<Office> | ErrorCapsule) {
  return {
    type: ACTION_SET_OFFICES,
    offices,
  };
}

async function loadOffices(filter?: ?{ [string]: string }) {
  try {
    const offices = await listOffices({
      ...filter,
      isDeleted: false,
      _sort: 'franchiseeId',
    }, officeShape);

    return setOffices(offices);
  } catch (err) {
    return setOffices(new ErrorCapsule(err, () => [
      setOffices(initialState.offices),
      loadOffices({ ...filter, _sort: 'franchiseeId' }),
    ]));
  }
}

async function deleteOffice(id) {
  await apolloMutate(
    gql`
      mutation ($id: String!) {
        deleteOffice(id: $id)
      }
    `,
    { id }
  );

  return {
    type: ACTION_DELETE_OFFICE,
    id,
  };
}

export function officesListReducer(
  state: OfficesListState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_OFFICES:
      return {
        ...state,
        offices: action.offices,
      };

    case ACTION_DELETE_OFFICE:
      return {
        ...state,
        offices: _filter(state.offices, ({ id }) => id !== action.id),
      };

    default:
      return state;
  }
}

const OfficesListPage = connect(
  ({ org }): OfficesListPageViewProps => ({
    offices: org.officesList.offices,
  }),
)(class extends Component<OfficesListPageViewProps> {
  constructor(props) {
    const { dispatch } = props;
    super(props);

    dispatch([
      setOffices(null),
      loadOffices(/* { field: mode } */),
    ]);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setOffices(null));
  }

  render() {
    const { dispatch } = this.props;
    return (
      <OfficesListPageView
        {...this.props}
        onDeleteOffice={(id) => dispatch(deleteOffice(id))}
      />
    );
  }
});

export function officesListPageRoutes(): Routes {
  return [
    {
      title: 'Офисы',
      path: '/offices/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <OfficesListPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
