// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { StatsOnFranchisee } from '../model/StatsOnFranchisee.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';

export type FranchiseeSalesPageViewProps = {
  statsOnFranchisees: null | Array<StatsOnFranchisee> | ErrorCapsule,
}

export default function FranchiseeSalesPageView({
  statsOnFranchisees,
}: FranchiseeSalesPageViewProps) {
  const { t } = useTranslation();

  if (!statsOnFranchisees) return <Loader />;
  if (statsOnFranchisees instanceof ErrorCapsule) return <LoadFailed error={statsOnFranchisees} />;

  return (
    <div className="franchisee-sales">
      <h1>Статистика по франчам</h1>
      <table className="table" style={{ width: 'auto' }}>
        <tbody>
          {statsOnFranchisees.map(({
            franchiseeId,
            franchisee,
            totalCuts,
          }) => (
            <tr key={franchiseeId} className="franchisee-sales__row">
              <td>
                <Link to={'/stats/sales/' + (franchiseeId ? franchiseeId + '/' : '')}>
                  {franchisee ? franchisee.title : 'Своя сеть'}
                </Link>
              </td>
              <td className="g__num">{totalCuts}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
