// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { prefixRoutes } from 'metaup/routing/routingUtils';
import { brandsListPageRoutes } from './BrandsListPage';
import { brandEditPageRoutes } from './BrandEditPage';
import { devicesListPageRoutes } from './DevicesListPage';
import { deviceEditPageRoutes } from './DeviceEditPage';
import { variantsListPageRoutes } from './VariantsListPage';
import { variantEditPageRoutes } from './VariantEditPage';
import { variantPageRoutes } from './VariantPage';
import { newsListPageRoutes } from './NewsListPage';
import { newsEditPageRoutes } from './NewsEditPage';
// ### GENERATED IMPORTS GO HERE ###

export function libraryRoutes() {
  return prefixRoutes('/library/', [
    brandsListPageRoutes(),
    brandEditPageRoutes(),
    devicesListPageRoutes(),
    deviceEditPageRoutes(),
    variantsListPageRoutes(),
    variantEditPageRoutes(),
    variantPageRoutes(),
    newsListPageRoutes(),
    newsEditPageRoutes(),
    // ### GENERATED ROUTES GO HERE ###
  ]);
}
