// @flow
import _flatten from 'lodash/flatten';
import { staticsRoutes } from '../../statics/statics.routes';
import { authRoutes } from '../../auth/auth.routes';
import { usersRoutes } from '../../users/users.routes';
import { printRoutes } from '../../print/print.routes';
import { libraryRoutes } from '../../library/library.routes';
import { frontdeskRoutes } from '../../frontdesk/frontdesk.routes';
import { orgRoutes } from '../../org/org.routes';
import { statsRoutes } from '../../stats/stats.routes';
import { error404PageCatchingRoute } from '../../statics/Error404Page';

export const appRoutes = _flatten([
  ...staticsRoutes(),
  ...authRoutes(),
  ...frontdeskRoutes(),
  ...libraryRoutes(),
  ...orgRoutes(),
  ...printRoutes(),
  ...statsRoutes(),
  ...usersRoutes(),
  error404PageCatchingRoute(),
]);
