// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Device } from '../../library/model/Device.model';
import type { Brand } from '../../library/model/Brand.model';

export type CutPageViewProps = {
  brand: Brand,
  devices: Array<Device>,
}

export default function CutPageDevicesView({
  brand,
  devices,
}: CutPageViewProps) {
  const { t } = useTranslation();

  return (
    <div className="cut">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/frontdesk/">Бренды</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">{brand.name}</li>
        </ol>
      </nav>

      <h1>Выберите модель</h1>
      <div className="row">
        {devices.map(({
          id,
          name,
          preview,
        }) => (
          <div key={id} className="col-4 pb-4">
            <Link to={`/frontdesk/${brand.id}/${id}/`} className="card flex-row">
              <div
                className="cut__tile-image"
                style={{ backgroundImage: preview ? `url(${preview.fit.url})` : null }}
              />
              <div className="card-body">
                <h5 className="card-title">{name}</h5>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
