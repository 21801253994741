// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { safeCombineReducers } from 'metaup/redux/reduxUtils';
import { brandsListReducer } from './BrandsListPage';
import { brandEditReducer } from './BrandEditPage';
import { devicesListReducer } from './DevicesListPage';
import { deviceEditReducer } from './DeviceEditPage';
import { variantsListReducer } from './VariantsListPage';
import { variantEditReducer } from './VariantEditPage';
import { variantReducer } from './VariantPage';
import { newsListReducer } from './NewsListPage';
import { newsEditReducer } from './NewsEditPage';
import { newsReducer } from './NewsWidget';
// ### GENERATED IMPORTS GO HERE ###

export const namespace = 'library';

export const reducer = safeCombineReducers({
  brandsList: brandsListReducer,
  brandEdit: brandEditReducer,
  devicesList: devicesListReducer,
  deviceEdit: deviceEditReducer,
  variantsList: variantsListReducer,
  variantEdit: variantEditReducer,
  variant: variantReducer,
  newsList: newsListReducer,
  newsEdit: newsEditReducer,
  news: newsReducer,
  // ### GENERATED REDUCERS GO HERE ###
});
