// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { renderImageMeta } from '../../core/render/visualUtils';
import type { Device } from '../model/Device.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Brand } from '../model/Brand.model';
import { useDialog } from '../../elements/Dialog';

export type DevicesListPageViewProps = {
  brand: ?Brand,
  devices: null | Array<Device> | ErrorCapsule,
  onDeleteDevice: (id: string) => void,
}

export default function DevicesListPageView({
  brand,
  devices,
  onDeleteDevice,
}: DevicesListPageViewProps) {
  const { t } = useTranslation();
  const { confirm } = useDialog();

  if (!devices) return <Loader />;
  if (devices instanceof ErrorCapsule) return <LoadFailed error={devices} />;

  return (
    <div className="devices-list">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/library/brands/">Бренды</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">{brand.name}</li>
        </ol>
      </nav>
      <h1>Управление моделями</h1>

      <div className="mb-1 text-right">
        <Link to={`/library/device-add/${brand.id}/`} className="btn btn-primary btn-sm">добавить модель</Link>
      </div>
      <table className="table">
        <tbody>
          {devices.map(({
            id,
            deviceType: { name: deviceTypeName },
            name,
            preview,
          }) => (
            <tr key={id} className="devices-list__device">
              <td className="text-center w-25">{preview ? renderImageMeta(preview.fit) : null}</td>
              <td className="align-middle"><Link to={`/library/variants/${id}/`}>{name}</Link></td>
              <td className="align-middle">{deviceTypeName}</td>
              <td className="align-middle w-25">
                <Link to={`/library/device-edit/${id}/`} className="btn btn-primary btn-sm">
                  изменить
                </Link>
                &nbsp;
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={e => confirm({
                    renderBody: () => `Удаляем ${name}?`,
                    handler: () => onDeleteDevice(id),
                  })}
                >
                  удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
