// @flow
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import Backend from 'i18next-xhr-backend';
import { apolloSetLang } from '../data/apolloClient';

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,

    // Gettext-like behavior
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: false,

    returnNull: false,
    lng: 'ru',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format(value, format, lng) {
        if (moment.isMoment(value)) return value.format(format);
        return value;
      },
    },
  })
  .then(() => {
    apolloSetLang(i18n.language);
  });

export default i18n;
