// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DefaultForm,
  DefaultFormGlobalValidation,
  DefaultFormSubmit,
  DefaultFormText,
  DefaultFormTextArea,
} from '../../elements/DefaultForm';
import { NewsArticle, newsArticleMetaModel } from '../model/NewsArticle.model';

import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';

export type NewsEditPageViewProps = {
  newsArticle: null | NewsArticle | ErrorCapsule,
  onSubmit: (values: { [string]: any }) => void,
}

export default function NewsEditPageView({
  newsArticle,
  onSubmit,
}: NewsEditPageViewProps) {
  const { t } = useTranslation();

  if (!newsArticle) return <Loader />;
  if (newsArticle instanceof ErrorCapsule) return <LoadFailed error={newsArticle} />;
  const isCreating = newsArticle.id == null;

  return (
    <div className="news-edit">
      <h1>{isCreating ? 'Добавление новости' : 'Изменение новости'}</h1>
      <DefaultForm
        form="newsEdit"
        metaModel={newsArticleMetaModel}
        initialValues={newsArticle}
        onSubmit={values => onSubmit(values)}
      >
        <DefaultFormGlobalValidation />
        <DefaultFormTextArea attribute="text" autoFocus />
        <DefaultFormText attribute="date" type="date" />
        <DefaultFormSubmit label={isCreating ? 'Добавить' : 'Изменить'} />
      </DefaultForm>
    </div>
  );
}
