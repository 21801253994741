// @flow
import React, { useEffect } from 'react';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { sanitizeRouteParams } from 'metaup/routing/routingUtils';
import type { Routes } from 'metaup/routing/routingUtils';
import { listAuthAudits } from '../auth/model/AuthAudit.client';
import ErrorCapsule from '../core/exceptions/ErrorCapsule';

import LoginsHistoryPageView from './views/LoginsHistoryPageView';
import type { AuthAudit } from '../auth/model/AuthAudit.model';
import type { User } from '../users/model/User.model';
import { getAuthUser } from '../auth/authState';

type LoginsHistoryState = {
  authAudits: null | Array<AuthAudit> | ErrorCapsule,
}

const authAuditShape = `{
  id
  start
  end
  email
  error
  user {
    id
    email
    isFranchiseeManager
    isAdmin
    franchisee { id title }
    office { id title }
  }
  ip
  geo
  userAgent
  headers
}`;

const initialState: LoginsHistoryState = {
  authAudits: null,
};

const ACTION_SET_AUTH_AUDITS = 'stats/loginsHistory/ACTION_SET_AUTH_AUDITS';

function setAuthAudits(authAudits: null | Array<AuthAudit> | ErrorCapsule) {
  return {
    type: ACTION_SET_AUTH_AUDITS,
    authAudits,
  };
}

async function loadAuthAudits(filter?: ?{ [string]: string }) {
  try {
    const authAudits = await listAuthAudits({ ...filter, _sort: 'start-' }, authAuditShape);

    return setAuthAudits(authAudits);
  } catch (err) {
    return setAuthAudits(new ErrorCapsule(err, () => [
      setAuthAudits(initialState.authAudits),
      loadAuthAudits(filter),
    ]));
  }
}

export function loginsHistoryReducer(
  state: LoginsHistoryState = initialState,
  action: AnyAction
) {
  switch (action.type) {

    case ACTION_SET_AUTH_AUDITS:
      return {
        ...state,
        authAudits: action.authAudits,
      };

    default:
      return state;
  }
}

type Props = {}

function LoginsHistoryPage({}: Props) {
  const dispatch = useDispatch();
  const {
    authAudits,
  }: LoginsHistoryState = useSelector(({ stats }) => stats.loginsHistory);
  const {
    isAdmin,
  }: User = useSelector(getAuthUser);

  // Load AuthAudits
  useEffect(() => {
    dispatch([
      setAuthAudits(null),
      loadAuthAudits(/* { field: mode } */),
    ]);
    return () => dispatch(setAuthAudits(null));
  }, []);

  // Render
  return (
    <LoginsHistoryPageView
      isAdmin={isAdmin}
      authAudits={authAudits}
    />
  );
}

export function loginsHistoryPageRoutes(): Routes {
  return [
    {
      title: 'История входов',
      path: '/logins/',
      isEnabled: ({ isUser }) => isUser,
      render: params => (
        <LoginsHistoryPage
          {...sanitizeRouteParams(params, {
          })}
        />
      ),
      design: null, // eslint-disable-line global-require
    },
  ];
}
