// @flow
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import {
  apolloQuery,
  apolloSubmitForm,
  apolloSubscribe,
  getSubscriptionAuthorization,
} from '../../core/data/apolloClient';
import { printClientSessionMetaModel } from './PrintClientSession.model';

export function filterPrintClientSessionInput(values) {
  return _pickBy(values, (_, key) => key in printClientSessionMetaModel.rules || key === 'id');
}

export function listPrintClientSessions(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: PrintClientSessionsFilter) {
        listPrintClientSessions(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getPrintClientSession(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getPrintClientSession(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createPrintClientSession(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PrintClientSessionCreate!) {
        createPrintClientSession(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updatePrintClientSession(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: PrintClientSessionUpdate!) {
        updatePrintClientSession(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function onPrintClientSessions(shape, listener: SubscriptionListener) {
  return apolloSubscribe(
    gql`
      subscription ($authorization: String!) {
        printClientSessions(authorization: $authorization) { action preId id model ${shape} }
      }
    `,
    { authorization: getSubscriptionAuthorization() },
    listener
  );
}
