// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import { required, email } from 'redux-form-validators';
import { string } from '../../core/data/rules';
import type { User } from '../../users/model/User.model';
import type { MetaModel } from '../../core/data/rules';

export type AuthAudit = {|
  id: string,
  start: string,
  end: ?string,
  heartBeat: string,
  email: string,
  error: ?string,
  userId: ?string,
  user: ?User,
  ip: string,
  geo: ?string,
  userAgent: string,
  headers: any,
|}


export const authAuditMetaModel: MetaModel = {
  name: 'AuthAudit',
  rules: {
    start: [string(), required()],
    end: [string()],
    heartBeat: [string(), required()],
    email: [email(), required()],
    error: [string()],
    userId: [string()],
    ip: [string(), required()],
    geo: [string()],
    userAgent: [string(), required()],
    headers: [],
  },
};
