// @flow
// ### AUTOMATICALLY GENERATED FILE. Remove this comment to preserve your edits in the file. ###
import _pickBy from 'lodash/pickBy';
import gql from 'graphql-tag';
import { apolloQuery, apolloSubmitForm } from '../../core/data/apolloClient';
import { deviceMetaModel } from './Device.model';

export function filterDeviceInput(values) {
  return _pickBy(values, (_, key) => key in deviceMetaModel.rules || key === 'id');
}

export function listDevices(filter?: ?{ [string]: string }, shape) {
  return apolloQuery(
    gql`
      query ($filter: DevicesFilter) {
        listDevices(filter: $filter) ${shape}
      }
    `,
    { filter }
  );
}

export function getDevice(id: string, shape) {
  return apolloQuery(
    gql`
      query ($id: String!) {
        getDevice(id: $id) ${shape}
      }
    `,
    { id }
  );
}

export function createDevice(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: DeviceCreate!) {
        createDevice(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}

export function updateDevice(inputValues, outputShape) {
  return apolloSubmitForm(
    gql`
      mutation ($input: DeviceUpdate!) {
        updateDevice(input: $input) ${outputShape}
      }
    `,
    { input: inputValues }
  );
}
