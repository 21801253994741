// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { renderImageMeta } from '../../core/render/visualUtils';
import type { Variant } from '../model/Variant.model';
import Loader from '../../elements/Loader';
import LoadFailed from '../../elements/LoadFailed';
import ErrorCapsule from '../../core/exceptions/ErrorCapsule';
import type { Brand } from '../model/Brand.model';
import type { Device } from '../model/Device.model';
import { useDialog } from '../../elements/Dialog';

export type VariantsListPageViewProps = {
  brand: ?Brand,
  device: ?Device,
  variants: null | Array<Variant> | ErrorCapsule,
  rootProps: Object,
  actionErrors: ?string,
  isDragActive: boolean,
  onDeleteVariant: (id: string) => void,
}

export default function VariantsListPageView({
  brand,
  device,
  variants,
  rootProps,
  actionErrors,
  isDragActive,
  onDeleteVariant,
}: VariantsListPageViewProps) {
  const { t } = useTranslation();
  const { confirm } = useDialog();

  if (!variants) return <Loader />;
  if (variants instanceof ErrorCapsule) return <LoadFailed error={variants} />;

  return (
    <div
      className="variants-list"
      {...rootProps}
      style={{ background: isDragActive ? 'yellow' : null }}
    >
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/library/brands/">Бренды</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/library/devices/${brand.id}/`}>{brand.name}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">{device.name}</li>
        </ol>
      </nav>
      <h1>Управление файлами резов</h1>

      {actionErrors && (
        <div className="alert alert-danger" style={{ whiteSpace: 'pre-line' }}>{actionErrors}</div>
      )}

      <div className="mb-1 text-right">
        <Link to={`/library/variant-add/${device.id}/`} className="btn btn-primary btn-sm">добавить вариант</Link>
      </div>
      <table className="table">
        <tbody>
          {variants.map(({
            id,
            name,
            preview,
            guide,
            notes,
            isEnabled,
          }) => (
            <tr
              key={id}
              className={`variants-list__variant ${isEnabled ? '' : 'bg-warning'}`}
              title={isEnabled ? '' : 'Вариант отключен'}
            >
              <td className="text-center w-25">{preview && renderImageMeta(preview.fit)}</td>
              <td className="align-middle"><Link to={`/library/variant/${id}/`}>{name}</Link></td>
              <td className="align-middle w-25">
                <Link to={`/library/variant-edit/${id}/`} className="btn btn-primary btn-sm">
                  изменить
                </Link>
                &nbsp;
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={e => confirm({
                    renderBody: () => `Удаляем ${name}?`,
                    handler: () => onDeleteVariant(id),
                  })}
                >
                  удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
